export const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegowina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Congo, the Democratic Republic of the',
  'Cook Islands',
  'Costa Rica',
  "Cote d'Ivoire",
  'Croatia (Hrvatska)',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands (Malvinas)',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'France Metropolitan',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard and Mc Donald Islands',
  'Holy See (Vatican City State)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea, Democratic People's Republic of",
  'Korea, Republic of',
  'Kuwait',
  'Kyrgyzstan',
  "Lao, People's Democratic Republic",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia, The Former Yugoslav Republic of',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova, Republic of',
  'Monaco',
  'Mongolia',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia (Slovak Republic)',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'Spain',
  'Sri Lanka',
  'St. Helena',
  'St. Pierre and Miquelon',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen Islands',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan, Province of China',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna Islands',
  'Western Sahara',
  'Yemen',
  'Yugoslavia',
  'Zambia',
  'Zimbabwe',
]

export const religion = [
  'Christian',
  'Jewish',
  'Muslim',
  'Hindu',
  'Agnostic/Atheist',
  'Buddhist',
  'Chinese Traditionalist',
  'Primal-Indigenous',
  'African Traditionalist',
  'Sikh',
  'Other',
]

export const complexion = ['Fair', 'Medium', 'Dark', 'Olive']

export const bodytype = ['Petite', 'Fit', 'Athletic', 'Average', 'Rubenesque']

export const eyecolor = ['Brown', 'Blue', 'Green', 'Hazel', 'Gray']

export const hairtype = ['Curly', 'Wavy', 'Straight']

export const haircolor = [
  'Lt. Brown',
  'Md. Brown',
  'Dk. Brown',
  'Blonde',
  'Strawberry Blonde',
  'Black',
  'Red',
  'Red Brown',
]

export const races = [
  'African American',
  'Arab',
  'Arab American',
  'Armenian',
  'Asian Indian',
  'Brazilian',
  'British',
  'Cambodian',
  'Caucasian',
  'Chinese',
  'Colombian',
  'Czech',
  'Danish',
  'Dutch',
  'Egyptian',
  'English',
  'Eurasian',
  'Filipino',
  'French',
  'German',
  'Greek',
  'Hawaiian/Pacific Islander',
  'Hispanic',
  'Hungarian',
  'Indonesian',
  'Iranian',
  'Irish',
  'Italian',
  'Japanese',
  'Jewish',
  'Korean',
  'Lebanese',
  'Mexican',
  'Middle Eastern',
  'Native American',
  'Other European',
  'Pakastani',
  'Persian',
  'Polish',
  'Portugese',
  'Puerto Rican ',
  'Romanian',
  'Russian',
  'South American',
  'Spanish',
  'Swedish',
  'Syrian',
  'Thai',
  'Turkish',
  'Ukranian',
  'Vietnamese',
]

export const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District Of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]

export const handside = ['Right-handed', 'Left-handed', 'Ambidextrous']

export const maritalstatus = [
  'Married',
  'Single',
  'Divorced',
  'Widowed',
  'Committed Relationship',
  'Separated',
]

//export const sexorient = ['Homosexual','Heterosexual','Bisexual'];
export const sexorient = [
  'Bisexual',
  'Gay Woman/Lesbian',
  'Heterosexual/Straight',
  'Identity not listed',
  'Prefer not to disclose',
]

export const pregnum = [0, 1, 2, 3, 4, 5]

export const outcome = [
  'Miscarriage',
  'Abortion',
  'Stillbirth',
  'Premature Birth',
]

export const educationlevel = [
  'Highschool Graduate',
  'Currently in College',
  'Occupational School',
  'Technical College',
  'Associate Degree',
  'Bachelor Degree',
  "Master's Degree",
  'Ph.D. or M.D. Degree',
  'Other Doctoral Program',
  'Other Education',
]

export const healthstatus = ['Excellent', 'Good', 'Fair', 'Poor']

export const switch_options = ['Yes', 'No']

export const pregnancy_result = [
  'Positive',
  'Negative',
  'Twins',
  'Unknown',
  'Frozen',
]

export const referredby = ['Other', 'Friends', 'Magazine', 'Internet', 'Doctor']

export const bloodtype_options = [
  {
    title: 'A+',
    value: 'A+',
  },
  {
    title: 'A-',
    value: 'A-',
  },
  {
    title: 'B+',
    value: 'B+',
  },
  {
    title: 'B-',
    value: 'B-',
  },
  {
    title: 'O+',
    value: 'O+',
  },
  {
    title: 'O-',
    value: 'O-',
  },
  {
    title: 'AB+',
    value: 'AB',
  },
  {
    title: 'AB-',
    value: 'AB-',
  },
]

export const height_options = [
  {
    value: '',
    title: '',
  },
  {
    value: '4.11',
    title: '4’ 11”',
  },
  {
    value: '5',
    title: '5’',
  },
  {
    value: '5.01',
    title: '5’ 1”',
  },
  {
    value: '5.02',
    title: '5’ 2”',
  },
  {
    value: '5.03',
    title: '5’ 3”',
  },
  {
    value: '5.04',
    title: '5’ 4”',
  },
  {
    value: '5.05',
    title: '5’ 5”',
  },
  {
    value: '5.06',
    title: '5’ 6”',
  },
  {
    value: '5.07',
    title: '5’ 7”',
  },
  {
    value: '5.08',
    title: '5’ 8”',
  },
  {
    value: '5.09',
    title: '5’ 9”',
  },
  {
    value: '5.10',
    title: '5’ 10”',
  },
  {
    value: '5.11',
    title: '5’ 11”',
  },
  {
    value: '6',
    title: '6’',
  },
  {
    value: '6.01',
    title: '6’ 1”',
  },
  {
    value: '6.02',
    title: '6’ 2”',
  },
  {
    value: '6.03',
    title: '6’ 3”',
  },
  {
    value: '6.04',
    title: '6’ 4”',
  },
]

export const age_options = [
  {
    value: '',
    title: '',
  },
  {
    value: '21',
    title: '21',
  },
  {
    value: '22',
    title: '22',
  },
  {
    value: '23',
    title: '23',
  },
  {
    value: '24',
    title: '24',
  },
  {
    value: '25',
    title: '25',
  },
  {
    value: '26',
    title: '26',
  },
  {
    value: '27',
    title: '27',
  },
  {
    value: '28',
    title: '28',
  },
  {
    value: '29',
    title: '29',
  },
  {
    value: '30',
    title: '30',
  },
  {
    value: '31',
    title: '31',
  },
  {
    value: '32',
    title: '32',
  },
  {
    value: '33',
    title: '33',
  },
]

export const medicalconditions = [
  {
    value: 'historymigraine',
    title: 'Migraines',
  },
  {
    value: 'downs',
    title: "Down's Syndrome",
  },
  {
    value: 'mental',
    title: 'Mental retardation',
  },
  {
    value: 'siezure',
    title: 'Seizure disorder/Epilepsy',
  },
  {
    value: 'muscular',
    title: 'Muscular Dystrophy',
  },
  {
    value: 'deafness',
    title: 'Deafness or Hearing Problems',
  },
  {
    value: 'blindness',
    title: 'Blindness or Color Blindness',
  },
  {
    value: 'glasses',
    title: 'Corrective Lenses/Glasses',
  },
  {
    value: 'glaucoma',
    title: 'Glaucoma',
  },
  {
    value: 'sighted',
    title: 'Nearsighted or Farsighted',
  },
  {
    value: 'schizophrenia',
    title: 'Schizophrenia',
  },
  {
    value: 'depression',
    title: 'Suffers from Depression',
  },
  {
    value: 'birthdefect',
    title: 'Serious Birth defect',
  },
  {
    value: 'heartproblems',
    title: 'Heart Problems',
  },
  {
    value: 'miscarriage',
    title: 'More than 2 miscarriages',
  },
  {
    value: 'skindiscoloration',
    title: 'Skin discoloration/spots/rashes',
  },
  {
    value: 'cancer',
    title: 'Cancer - what type',
  },
  {
    value: 'hemophilia',
    title: 'Severe bleeding tendency (hemophilia)',
  },
  {
    value: 'stroke',
    title: 'Stroke',
  },
  {
    value: 'ulcer',
    title: 'Ulcer',
  },
  {
    value: 'ovariancyst',
    title: 'Ovarian Cysts',
  },
  {
    value: 'uterinefibroid',
    title: 'Uterine Fibroid',
  },
  {
    value: 'asthma',
    title: 'Asthma',
  },
  {
    value: 'hyperactivity',
    title: 'Hyperactivity Cysts',
  },
  {
    value: 'alcoholism',
    title: 'Alcoholism',
  },
  {
    value: 'drugaddiction',
    title: 'Drug Addiction',
  },
  {
    value: 'learningdisability',
    title: 'Learning Disability',
  },
  {
    value: 'diabetes',
    title: 'Diabetes',
  },
  {
    value: 'autism',
    title: 'Autism',
  },
]

export function goto_login() {
  window.location = '/login'
}

export function goto_profile() {
  window.location = '/profile?id=' + global.r_id
}

export function goto_donor_application() {
  window.location = '/donor-application'
}

export function goto_account() {
  window.location = '/account'
}

export function check_account_type() {
  if (global.type === 'donor') window.location = '/profile?id=' + global.r_id
}

export function check_login() {
  var token = window.localStorage.getItem('token')
  if (!token || token === '' || token === 'undefined') {
    window.location = '/login'
  }
  global.r_id = window.localStorage.getItem('r_id')
  global.type = window.localStorage.getItem('type')
  global.title =
    window.localStorage.getItem('first_name') +
    ' ' +
    window.localStorage.getItem('last_name')
  global.token = window.localStorage.getItem('token')
  global.email = window.localStorage.getItem('email')
}

export function is_donor() {
  return global.type === 'donor'
}

export function is_recipient() {
  return global.type === 'recipient'
}

export function save_location_settings(addr, lat, lng, distance) {
  var location = { address: addr, lat: lat, lng: lng, distance: distance }
  var location_json = JSON.stringify(location)

  // console.log('Save Sort Settings:' + location_json)
  localStorage.setItem('location', location_json)
}

export function load_location_settings() {
  const settings_json = localStorage.getItem('location')
  var settings
  if (settings_json) {
    settings = JSON.parse(settings_json)
  }
  return settings
}

export function save_sort_settings(sortby, dir) {
  var settings = { sortby: sortby, dir: dir }
  var settings_json = JSON.stringify(settings)
  // console.log('Save Sort Settings:' + settings_json)
  localStorage.setItem('sort', settings_json)
}

export function load_sort_settings() {
  const settings_json = localStorage.getItem('sort')
  var settings
  if (settings_json) {
    settings = JSON.parse(settings_json)
  }
  return settings
}

export function valid_email(email) {
  var regex =
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
  if (!regex.test(email)) {
    return false
  } else {
    return true
  }
}
