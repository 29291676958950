import React, { Component } from 'react'

import $ from 'jquery'
import Header from './header'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import * as F_UTIL from './helper'
import './App.css'

class TLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.addNotification = this.addNotification.bind(this)
    this.notificationDOMRef = React.createRef()
  }

  componentWillMount() {
    //window.localStorage.clear();
    document.title = 'Members Login'
  }

  componentDidMount() {}

  addNotification() {
    this.notificationDOMRef.current.addNotification({
      title: 'Login',
      message: 'Incorrect username or password!',
      type: 'danger',
      insert: 'top',
      container: 'bottom-center',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: { duration: 4000 },
      dismissable: { click: true },
    })
  }

  doLogin(e) {
    e.preventDefault()
    const form = e.target
    const data = new FormData(form)

    var url = global.api_url + 'login/check_login'

    //window.localStorage.clear();

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: { email: data.get('email'), password: data.get('password') },
      cache: false,
      crossDomain: true,
      success: function (rsp) {
        console.log('Ajax Success')

        if (rsp.success === true) {
          window.localStorage.setItem('token', rsp.token)
          window.localStorage.setItem('r_id', rsp.info.id)
          window.localStorage.setItem('first_name', rsp.info.firstname)
          window.localStorage.setItem('last_name', rsp.info.lastname)
          window.localStorage.setItem('email', rsp.info.email)
          window.localStorage.setItem('type', rsp.type)
          //window.localStorage.setItem('token', rsp.token);
          global.r_id = rsp.info.id
          global.type = rsp.type
          if (F_UTIL.is_donor()) {
            //F_UTIL.goto_donor_application();

            if (
              rsp.info.appstatus === 'incomplete' ||
              rsp.info.appstatus === 'pending' ||
              rsp.info.appstatus === 'approved'
            ) {
              F_UTIL.goto_donor_application()
            } else {
              F_UTIL.goto_profile()
            }
          } else if (F_UTIL.is_recipient()) {
            F_UTIL.goto_account()
          }
        } else {
          this.addNotification()
        }
      }.bind(this),
    })
  }

  forgotpassword() {}

  render() {
    console.log('Content render')
    return (
      <div>
        <Header></Header>
        <ReactNotification ref={this.notificationDOMRef} />
        <div className="content-overlay">
          <div className="login-overlay">
            <div className="login-widget">
              <div className="login-box">
                <h1 className="title">MEMBER LOGIN</h1>
                <form className="form-login" onSubmit={this.doLogin.bind(this)}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="username"
                      defaultValue=""
                    ></input>
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="password"
                      defaultValue=""
                    ></input>
                  </div>
                  <div className="form-group">
                    <input
                      type="submit"
                      value="Sign In"
                      className="btn btn-login"
                    ></input>
                  </div>
                </form>

                <a className="u-link text-left" href="/forgot-password">
                  forgot password?
                </a>
              </div>
              <div className="signup-box">
                <h2 className="title">New Here?</h2>
                <a
                  className="u-link"
                  target="_blank"
                  href="/recipient-registration"
                >
                  Intended Parent Registration
                </a>
                <a
                  className="u-link"
                  target="_blank"
                  href="/donor-registration"
                >
                  Donor Registration
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TLogin
