import React, { Component, useEffect, useState } from 'react'

import $ from 'jquery'
import Header from './header'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'react-datepicker/dist/react-datepicker.css'
import '../App.css'
import ReCAPTCHA from 'react-google-recaptcha'
import * as F_UTIL from '../helper'
import axios from 'axios'

/* Local Image Preview */
function FileArt(props) {
  const { file, classes, name } = props
  const [img, setImg] = useState(null)

  useEffect(() => {
    if (file) {
      const fr = new FileReader()
      fr.onload = function () {
        const img = new Image()
        img.onload = function () {
          setImg(img)
        }
        img.src = fr.result
      }
      fr.readAsDataURL(file)
    } else {
      setImg(null)
    }
  }, [file])

  var imgSrc = img ? img.src : ''
  return (
    <div className="profileImage">{img && <img key={name} src={imgSrc} />}</div>
  )
}

class TDonor_Register extends Component {
  constructor(props) {
    super(props)
    var recaptcha = false
    if (process.env.REACT_APP_MODE === 'development') {
      recaptcha = true // for dev, pretend the user checked recaptcha already
    }
    var key = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY
    this.state = {
      startDate: '',
      confirm: false,
      message: '',
      recaptcha: recaptcha,
      googleRecaptchaKey: key,
      profileImg: null,
    }
    this.handleChange = this.handleChange.bind(this)
    this.addNotification = this.addNotification.bind(this)
    this.notificationDOMRef = React.createRef()
    this.onChange = this.onChange.bind(this)
  }

  componentWillMount() {
    document.title = 'Donor Registration'
  }

  componentDidMount() {}

  addNotification(type, msg) {
    this.notificationDOMRef.current.addNotification({
      message: msg,
      type: type,
      insert: 'top',
      container: 'bottom-center',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
    })
  }

  handleChange(date) {
    this.setState({
      startDate: date,
    })
  }

  clickBMI() {
    window.open(
      'https://www.nhlbi.nih.gov/health/educational/lose_wt/BMI/bmicalc.htm',
    )
  }

  render_header() {
    return (
      <div className="header-box">
        <h1 className="s-heading">Donor Registration</h1>
        <p className="s-label1 mt__20">
          Thank you for your interest in becoming an Egg Donor! It's the
          generosity of women like you who help infertile couples make their
          dreams of becoming parents come true. We acknowledge you for choosing
          to be an contribution to the lives of others. Please complete the form
          below to get started.
        </p>
      </div>
    )
  }

  do_login() {
    var url = global.api_url + 'login/check_login'

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: { email: $('#email').val(), password: $('#password').val() },
      cache: false,
      crossDomain: true,
      success: function (rsp) {
        console.log('Ajax Success')

        if (rsp.success === true) {
          window.localStorage.setItem('token', rsp.token)
          window.localStorage.setItem('r_id', rsp.info.id)
          window.localStorage.setItem('first_name', rsp.info.firstname)
          window.localStorage.setItem('last_name', rsp.info.lastname)
          window.localStorage.setItem('type', rsp.type)
          //window.localStorage.setItem('token', rsp.token);
          global.r_id = rsp.info.id
          global.type = rsp.type
          if (F_UTIL.is_donor()) {
            if (
              rsp.info.appstatus === 'incomplete' ||
              rsp.info.appstatus === 'pending' ||
              rsp.info.appstatus === 'approved'
            ) {
              F_UTIL.goto_donor_application()
            } else {
              F_UTIL.goto_profile()
            }
          } else if (F_UTIL.is_recipient()) {
            F_UTIL.goto_account()
          }
        } else {
          this.addNotification()
        }
      }.bind(this),
    })
  }

  changeNumber(e) {
    var key = String.fromCharCode(!e.charCode ? e.which : e.charCode)
    console.log('Event:' + e.target.name + ',' + e.target.value + ',' + key)
    if (isNaN(key)) {
      console.log('No Number')
      e.preventDefault()
      return false
    }
  }

  calculate_age = (dob1) => {
    var today = new Date()
    var birthDate = new Date(dob1) // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear()
    var m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--
    }
    console.log(age_now)
    return age_now
  }

  register_submit(e) {
    e.preventDefault()

    const { profileImg } = this.state
    var year = parseInt($('#year').val())
    var month = parseInt($('#month').val())
    var day = parseInt($('#day').val())
    var password = $('#password').val()
    var confirm = $('#confirm').val()

    $('.sform-control').removeClass('has-error')
    $('.sform-control3').removeClass('has-error')
    $('#profileimage_label').removeClass('has-error')
    if ($('#email').val() === '') {
      $('#email').addClass('has-error')
      this.addNotification('danger', 'Email required')
      return
    }
    if (!F_UTIL.valid_email($('#email').val())) {
      $('#email').addClass('has-error')
      this.addNotification('danger', 'Email incorrect')
      return
    }

    if (password === '') {
      $('#password').addClass('has-error')
      this.addNotification('danger', 'Password required')
      return
    }

    if (password !== confirm) {
      this.addNotification('danger', 'Password incorrect')
      return
    }

    if ($('#first-name').val() === '') {
      $('#first-name').addClass('has-error')
      this.addNotification('danger', 'First Name required')
      return
    }

    if ($('#last-name').val() === '') {
      $('#last-name').addClass('has-error')
      this.addNotification('danger', 'Last Name required')
      return
    }

    if ($('#month').val() === '') {
      $('#month').addClass('has-error')
      this.addNotification('danger', 'Date Month incorrect')
      return
    }

    if (month > 12) {
      $('#month').addClass('has-error')
      this.addNotification('danger', 'Date Month incorrect')
      return
    }

    if ($('#day').val() === '') {
      $('#day').addClass('has-error')
      this.addNotification('danger', 'Date incorrect')
      return
    }

    if (day > 31) {
      $('#day').addClass('has-error')
      this.addNotification('danger', 'Date Day incorrect')
      return
    }

    if ($('#year').val() === '') {
      $('#year').addClass('has-error')
      this.addNotification('danger', 'Date incorrect')
      return
    }

    let age = this.calculate_age(`${month}/${day}/${year}`)
    if (age < 21 || age > 30) {
      $('#year').addClass('has-error')
      this.addNotification('danger', 'Donor age should be between 21 to 30')
      return
    }

    if ($('#height').val() === '') {
      $('#height').addClass('has-error')
      this.addNotification('danger', 'Height required')
      return
    }

    if ($('#weight').val() === '') {
      $('#weight').addClass('has-error')
      this.addNotification('danger', 'Weight required')
      return
    }

    if ($('#country').val() === '') {
      $('#country').addClass('has-error')
      this.addNotification('danger', 'Country required')
      return
    }

    if ($('#weight').val() === '') {
      $('#weight').addClass('has-error')
      this.addNotification('danger', 'Weight required')
      return
    }

    if ($('#smoker').val() === '') {
      $('#smoker').addClass('has-error')
      this.addNotification('danger', 'Smoker field required')
      return
    }

    if (profileImg === null) {
      $('#profileimage_label').addClass('has-error')
      this.addNotification('danger', 'Profile Image required')
      return
    }

    var data = $('#register-form').serializeArray()
    var domain = window.location.protocol + '//' + window.location.host
    data.push({ name: 'domain', value: domain })

    var url = global.api_url + 'donor/register'
    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: data,
      cache: false,
      crossDomain: true,
      success: function (rsp) {
        console.log('Ajax Success')
        //this.addNotification();
        if (rsp.success === true) {
          var donor_id = rsp.id
          this.upload_profileimage(donor_id, profileImg)
        } else {
          this.addNotification('danger', rsp.message)
        }
      }.bind(this),
    })
  }

  upload_profileimage = (donor_id, file) => {
    const fd = new FormData()

    fd.append('id', donor_id)
    fd.append('image[]', file, file.name)

    var url = global.api_url + 'donor/uploadProfileImages/'
    //fd.append('image', this.state.imageFiles, this.state.imageFiles.name);
    axios
      .post(url, fd, {
        headers: { 'X-API-KEY': process.env.REACT_APP_API_KEY },
        onUploadProgress: (ProgressEvent) => {
          var percent = Math.round(
            (ProgressEvent.loaded / ProgressEvent.total) * 100,
          )
          console.log('Upload Progress:' + percent + '%')
          //this.setState({uploadingPrgress:percent});
        },
      })
      .then((res) => {
        console.log('Upload Completed, Try refreshing')
        this.addNotification('success', 'Thank You for Applying!')
        //F_UTIL.goto_login();
        this.do_login()
      })
  }

  fileSelectedHandler = (file) => {
    var msg = ''
    if (file.size > 10000000) {
      msg = 'File size is too big'
      this.addNotification('danger', msg)
      return
    }

    this.setState({ profileImg: file })
  }

  render_form() {
    const { recaptcha, profileImg, googleRecaptchaKey } = this.state
    return (
      <div className="form-box1">
        <form onSubmit={this.register_submit.bind(this)} id="register-form">
          <div className="row mt__10">
            <div className="col-md-12 col-xs-12">
              <div className="form-group text-left">
                <label class="s-subtitle">Create Your Donor Account</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-xs-12">
              <input
                type="email"
                className="sform-control"
                name="values[email]"
                id="email"
                placeholder="email address"
              />
            </div>
          </div>

          <div className="row mt__20">
            <div className="col-md-6 col-xs-12">
              <div className="form-group text-left">
                <input
                  type="password"
                  className="sform-control"
                  name="values[password]"
                  id="password"
                  placeholder="password"
                />
              </div>
            </div>

            <div className="col-md-6 col-xs-12">
              <div className="form-group text-left">
                <input
                  type="password"
                  className="sform-control"
                  name="values[confirm]"
                  id="confirm"
                  placeholder="confirm password"
                />
              </div>
            </div>
          </div>

          <div className="row mt__20">
            <div className="col-md-6 col-xs-12">
              <div className="form-group text-left">
                <input
                  type="text"
                  className="sform-control"
                  name="values[firstname]"
                  id="first-name"
                  placeholder="first name"
                />
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="form-group text-left">
                <input
                  type="text"
                  className="sform-control"
                  name="values[lastname]"
                  id="last-name"
                  placeholder="last name"
                />
              </div>
            </div>
          </div>

          <div className="row mt__30">
            <div className="col-md-12 col-xs-12">
              <div className="form-group text-left">
                <label class="s-subtitle">Prequalification Questions</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 col-xs-12">
              <div className="form-group text-left">
                <label class="s-label6">Date of Birth</label>
                <input
                  type="text"
                  className="sform-control3"
                  name="values[month]"
                  id="month"
                  onKeyPress={this.changeNumber.bind(this)}
                  placeholder="mm"
                />
              </div>
            </div>
            <div className="col-md-4 col-xs-12">
              <div className="form-group text-left">
                <label class="s-label6">&nbsp;</label>
                <input
                  type="text"
                  className="sform-control3"
                  name="values[day]"
                  id="day"
                  onKeyPress={this.changeNumber.bind(this)}
                  placeholder="dd"
                />
              </div>
            </div>
            <div className="col-md-4 col-xs-12">
              <div className="form-group text-left">
                <label class="s-label6">&nbsp;</label>
                <input
                  type="text"
                  className="sform-control3"
                  name="values[year]"
                  id="year"
                  onKeyPress={this.changeNumber.bind(this)}
                  placeholder="yyyy"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 col-xs-12">
              <div className="form-group text-left">
                <label class="s-label6">Height</label>
                <select
                  name="values[height]"
                  className="sform-control3"
                  id="height"
                >
                  <option value=""></option>
                  {F_UTIL.height_options.map(function (obj, i) {
                    return <option value={obj.value}>{obj.title}</option>
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-4 col-xs-12">
              <div className="form-group text-left">
                <label className="s-label6">Weight</label>
                <input
                  type="text"
                  className="sform-control3"
                  name="values[weight]"
                  id="weight"
                  placeholder=""
                />
              </div>
            </div>
            <div className="col-md-4 col-xs-12">
              <div className="form-group text-left">
                <label
                  className="s-label6 side"
                  onClick={this.clickBMI.bind(this)}
                >
                  (BMI Calculator)
                </label>
              </div>
            </div>
          </div>

          <div className="row text-left mb__20">
            <div className="col-md-4 col-xs-12">
              <div className="form-group text-left">
                <label className="s-label6">Residential Country</label>
                <select
                  className="sform-control3"
                  name="values[country]"
                  id="country"
                >
                  <option value=""></option>
                  {F_UTIL.countries.map(function (obj, i) {
                    return <option value={obj}>{obj}</option>
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-8 col-xs-12">
              <div className="form-group text-left">
                <label className="s-label6 side1">
                  Do you currently smoke or use recreational/illegal drugs?
                </label>
                <select
                  name="values[smoker]"
                  className="sform-control3"
                  id="smoker"
                >
                  <option value=""></option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row mt__30">
            <div className="col-md-12 col-xs-12">
              <div className="form-group text-left">
                <label class="s-subtitle">Profile Photo</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-xs-12">
              <div className="form-group text-left">
                <label class="s-label3" id="profileimage_label">
                  Please upload photos for your profile
                </label>
              </div>

              <div className="form-group text-left">
                <input
                  type="file"
                  accept="image/*"
                  id="file"
                  onChange={(e) => this.fileSelectedHandler(e.target.files[0])}
                  className="ignore"
                  ref={(fileInput) => (this.fileInput = fileInput)}
                  style={{ display: 'none' }}
                />
                <div
                  onClick={() => this.fileInput.click()}
                  className="btn btn-upload ignore"
                >
                  Upload
                </div>
              </div>
            </div>
          </div>

          {profileImg && (
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <FileArt name="profileImg" key="profileImg" file={profileImg} />
              </div>
            </div>
          )}

          {recaptcha && (
            <div className="row mt__30">
              <div className="col-12">
                <div className="form-group text-left">
                  <button type="submit" className="btn btn-register">
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}

          {recaptcha === false && (
            <div className="row mt__30">
              <div className="col-12">
                <ReCAPTCHA
                  sitekey={googleRecaptchaKey}
                  onChange={this.onChange}
                />
              </div>
            </div>
          )}
        </form>
      </div>
    )
  }

  render_description() {
    return (
      <div className="title-box1 text-left">
        <p className="s-label3">
          Please keep in mind that all potential egg donors must meet <br></br>
          the following basic requirements, criteria, and qualifications.
        </p>

        <br></br>
        <p className="s-label1">
          - Egg Donor Age Requirements: 21 to 29 years old
        </p>

        <p className="s-label1">- Well educated</p>

        <p className="s-label1">- Proportionate height and weight</p>

        <p className="s-label1">
          - Mature and prepared to help a couple have a child
        </p>

        <p className="s-label1">- Responsible and reliable</p>

        <p className="s-label1">- Healthy, with a healthy family history</p>

        <p className="s-label1">- Non-smoker/No drug use</p>
      </div>
    )
  }

  render_login() {
    return (
      <div className="title-box2">
        <div className="s-title2">Already Have An Account?</div>

        <a className="u-link" href="/login">
          Login Here
        </a>
      </div>
    )
  }

  render_content() {
    return (
      <div className="r-content">
        {this.render_header()}

        {this.render_form()}

        {this.render_description()}

        {this.render_login()}
      </div>
    )
  }

  render_titlebox3() {
    return (
      <div className="title-box3">
        <p className="s-label2">
          By registering, you are giving us permission to contact you. This
          information is confidential and is never disclosed to third parties.
          Please check your spam folder and be sure to whitelist @eggdonor.com
          with your email provider to ensure all messages from us are received.
        </p>
      </div>
    )
  }

  render_confirm() {
    const { message } = this.state
    return (
      <div>
        <Header></Header>
        <div className="content-overlay">
          <div className="login-overlay">
            <div className="widget-message">{message}</div>
            <a className="u-link pt-5" href="/login">
              Go to login
            </a>
          </div>
        </div>
      </div>
    )
  }

  onChange(value) {
    console.log('Captcha value:', value)
    this.setState({ recaptcha: true })
  }

  render() {
    const { confirm } = this.state

    if (confirm === true) {
      return this.render_confirm()
    }
    return (
      <div>
        <Header></Header>
        <ReactNotification ref={this.notificationDOMRef} />

        {this.render_content()}

        {this.render_titlebox3()}
      </div>
    )
  }
}

export default TDonor_Register
