const FILTER_STATES = [
  {
    id: 0,
    value: '',
    label: 'All',
  },
  {
    id: 1,
    value: 'Alabama',
    label: 'Alabama',
  },
  {
    id: 2,
    value: 'Alaska',
    label: 'Alaska',
  },
  {
    id: 3,
    value: 'Arizona',
    label: 'Arizona',
  },
  {
    id: 4,
    value: 'Arkansas',
    label: 'Arkansas',
  },
  {
    id: 5,
    value: 'California',
    label: 'California',
  },
  {
    id: 6,
    value: 'Colorado',
    label: 'Colorado',
  },
  {
    id: 7,
    value: 'Connecticut',
    label: 'Connecticut',
  },
  {
    id: 8,
    value: 'Delaware',
    label: 'Delaware',
  },
  {
    id: 9,
    value: 'District of Columbia',
    label: 'District of Columbia',
  },
  {
    id: 10,
    value: 'Florida',
    label: 'Florida',
  },
  {
    id: 11,
    value: 'Georgia',
    label: 'Georgia',
  },
  {
    id: 12,
    value: 'Hawaii',
    label: 'Hawaii',
  },
  {
    id: 13,
    value: 'Idaho',
    label: 'Idaho',
  },
  {
    id: 14,
    value: 'Illinois',
    label: 'Illinois',
  },
  {
    id: 15,
    value: 'Indiana',
    label: 'Indiana',
  },
  {
    id: 16,
    value: 'Iowa',
    label: 'Iowa',
  },
  {
    id: 17,
    value: 'Kansas',
    label: 'Kansas',
  },
  {
    id: 18,
    value: 'Kentucky',
    label: 'Kentucky',
  },
  {
    id: 19,
    value: 'Louisiana',
    label: 'Louisiana',
  },
  {
    id: 20,
    value: 'Maine',
    label: 'Maine',
  },
  {
    id: 21,
    value: 'Montana',
    label: 'Montana',
  },
  {
    id: 22,
    value: 'Nebraska',
    label: 'Nebraska',
  },
  {
    id: 23,
    value: 'Nevada',
    label: 'Nevada',
  },
  {
    id: 24,
    value: 'New Hampshire',
    label: 'New Hampshire',
  },
  {
    id: 25,
    value: 'New Jersey',
    label: 'New Jersey',
  },
  {
    id: 26,
    value: 'New Mexico',
    label: 'New Mexico',
  },
  {
    id: 27,
    value: 'New York',
    label: 'New York',
  },
  {
    id: 28,
    value: 'North Carolina',
    label: 'North Carolina',
  },
  {
    id: 29,
    value: 'North Dakota',
    label: 'North Dakota',
  },
  {
    id: 30,
    value: 'Ohio',
    label: 'Ohio',
  },
  {
    id: 31,
    value: 'Oklahoma',
    label: 'Oklahoma',
  },
  {
    id: 32,
    value: 'Oregon',
    label: 'Oregon',
  },
  {
    id: 33,
    value: 'Maryland',
    label: 'Maryland',
  },
  {
    id: 34,
    value: 'Massachusetts',
    label: 'Massachusetts',
  },
  {
    id: 35,
    value: 'Michigan',
    label: 'Michigan',
  },
  {
    id: 36,
    value: 'Minnesota',
    label: 'Minnesota',
  },
  {
    id: 37,
    value: 'Mississippi',
    label: 'Mississippi',
  },
  {
    id: 38,
    value: 'Missouri',
    label: 'Missouri',
  },
  {
    id: 39,
    value: 'Pennsylvania',
    label: 'Pennsylvania',
  },
  {
    id: 40,
    value: 'Rhode Island',
    label: 'Rhode Island',
  },
  {
    id: 41,
    value: 'South Carolina',
    label: 'South Carolina',
  },
  {
    id: 42,
    value: 'South Dakota',
    label: 'South Dakota',
  },
  {
    id: 43,
    value: 'Tennessee',
    label: 'Tennessee',
  },
  {
    id: 44,
    value: 'Texas',
    label: 'Texas',
  },
  {
    id: 45,
    value: 'Utah',
    label: 'Utah',
  },
  {
    id: 46,
    value: 'Vermont',
    label: 'Vermont',
  },
  {
    id: 47,
    value: 'Virginia',
    label: 'Virginia',
  },
  {
    id: 48,
    value: 'Washington',
    label: 'Washington',
  },
  {
    id: 49,
    value: 'West Virginia',
    label: 'West Virginia',
  },
  {
    id: 50,
    value: 'Wisconsin',
    label: 'Wisconsin',
  },
  {
    id: 51,
    value: 'Wyoming',
    label: 'Wyoming',
  },
]

export default FILTER_STATES
