import React, { Component } from 'react'

import $ from 'jquery'
import Header from './header'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import * as F_UTIL from '../helper'

import '../App.css'

class TRecipient_Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active_pane: 0,
      confirm: false,
      message: '',
    }
    this.addNotification = this.addNotification.bind(this)
    this.notificationDOMRef = React.createRef()
  }

  componentWillMount() {
    document.title = 'Parent Registration'
  }

  componentDidMount() {}

  addNotification(type, msg) {
    this.notificationDOMRef.current.addNotification({
      message: msg,
      type: type,
      insert: 'top',
      container: 'bottom-center',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
    })
  }

  render_header() {
    const { active_pane } = this.state
    return (
      <div className="header-box">
        <h1 className="s-heading">Parent Registration</h1>
        {active_pane === 0 ? (
          <div className="s-title1">
            Thank you for your interest in our elite database of egg donors!
          </div>
        ) : (
          <div className="s-title1">
            Please enter the additional info below to help us get started.
          </div>
        )}

        <p className="s-label1">
          Through our careful selection of prescreened, intelligent, healthy,
          beautiful, and unique egg donors from around the world, we have helped
          more than 14,000 recipient families since 1989. We invite you to
          register to view the largest database of elite egg donor profiles—all
          without charge!
        </p>
      </div>
    )
  }

  showPane1() {
    $('#pane2').fadeOut(500)
    $('#pane1').fadeIn(1000)
    this.setState({ active_pane: 0 })
  }

  showPane2() {
    $('#pane1').fadeOut(500)
    $('#pane2').fadeIn(1000)
    this.setState({ active_pane: 1 })
  }

  register_submit() {
    var data = $('#register-form').serializeArray()

    var domain = window.location.protocol + '//' + window.location.host
    data.push({ name: 'domain', value: domain })

    var url = global.api_url + 'recipient/register'
    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: data,
      cache: false,
      crossDomain: true,
      success: function (rsp) {
        console.log('Ajax Success')
        //this.addNotification();
        if (rsp.success === true) {
          this.addNotification('success', rsp.message)
          //F_UTIL.goto_login();
          this.setState({ confirm: true, message: rsp.message })
        } else {
          this.addNotification('danger', rsp.message)
        }
      }.bind(this),
    })
  }

  onClickNext() {
    var password = $('#password').val()
    var confirm = $('#confirm').val()

    $('.sform-control').removeClass('has-error')

    if ($('#email').val() === '') {
      $('#email').addClass('has-error')
      this.addNotification('danger', 'Email required')
      return
    }

    if (!F_UTIL.valid_email($('#email').val())) {
      $('#email').addClass('has-error')
      this.addNotification('danger', 'Email incorrect')
      return
    }

    if ($('#hphone').val() === '') {
      $('#hphone').addClass('has-error')
      this.addNotification('danger', 'Primary Phone Number required')
      return
    }

    if (password === '') {
      $('#password').addClass('has-error')
      this.addNotification('danger', 'Password required')
      return
    }

    if (password !== confirm) {
      $('#password').addClass('has-error')
      $('#confirm').addClass('has-error')
      this.addNotification('danger', 'Password incorrect')
      return
    }

    if ($('#firstname').val() === '') {
      $('#firstname').addClass('has-error')
      this.addNotification('danger', 'First Name required')
      return
    }

    if ($('#lastname').val() === '') {
      $('#lastname').addClass('has-error')
      this.addNotification('danger', 'Last Name required')
      return
    }

    var data = $('#register-form').serializeArray()
    var domain = window.location.protocol + '//' + window.location.host

    var url = global.api_url + 'recipient/existuser'
    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: data,
      cache: false,
      crossDomain: true,
      success: function (rsp) {
        console.log('Ajax Success')
        //this.addNotification();
        if (rsp.success === true) {
          //this.addNotification('success', rsp.message);
          this.showPane2()
        } else {
          this.addNotification('danger', rsp.message)
          $('#email').addClass('has-error')
          $('#email').select()
        }
      }.bind(this),
    })
  }

  onClickSubmit() {
    $('.sform-control').removeClass('has-error')

    if ($('#physician').val() === '') {
      $('#physician').addClass('has-error')
      this.addNotification('danger', "Physician's Name required")
      return
    }

    if ($('#referredby').val() === '') {
      $('#referredby').addClass('has-error')
      this.addNotification('danger', 'Referred By required')
      return
    }
    /*
        if ($('#fertileproblem').val() === '') {
            $('#fertileproblem').addClass('has-error');
            this.addNotification('danger', 'Fertillity required');
            return; 
        }

        if ($('#donor_timeframe').val() === '') {
            $('#donor_timeframe').addClass('has-error');
            this.addNotification('danger', 'Timeframe required');
            return; 
        }
*/
    this.register_submit()
  }

  render_newform() {
    const { active_pane } = this.state

    return (
      <div className="form-box1">
        <form id="register-form">
          <div id="pane1">
            <div className="pane-content">
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label class="s-label6">Email Address</label>
                    <input
                      type="email"
                      className="sform-control"
                      name="values[email]"
                      id="email"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label class="s-label6">Primary Phone Number</label>
                    <input
                      type="text"
                      className="sform-control"
                      name="values[hphone]"
                      id="hphone"
                      placeholder=""
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row mt__10">
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label class="s-label6">Password</label>
                    <input
                      type="password"
                      className="sform-control"
                      name="values[password]"
                      id="password"
                      placeholder=""
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label class="s-label6">Confirm Password</label>
                    <input
                      type="password"
                      className="sform-control"
                      name="values[confirm]"
                      id="confirm"
                      placeholder=""
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row mt__10">
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label class="s-label6">First Name</label>
                    <input
                      type="text"
                      className="sform-control"
                      name="values[firstname]"
                      id="firstname"
                      placeholder=""
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label class="s-label6">Last Name</label>
                    <input
                      type="text"
                      className="sform-control"
                      name="values[lastname]"
                      id="lastname"
                      placeholder=""
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt__10">
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-register"
                  onClick={this.onClickNext.bind(this)}
                >
                  ENTER
                </button>
              </div>
            </div>
          </div>

          <div id="pane2">
            <div className="pane-content">
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label class="s-label6">Physician's Name</label>
                    <input
                      type="text"
                      className="sform-control"
                      name="values[physician]"
                      id="physician"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label class="s-label6">Referred By</label>
                    <select
                      className="sform-control"
                      name="values[referredby]"
                      id="referredby"
                    >
                      <option value=""></option>
                      {F_UTIL.referredby.map(function (obj, i) {
                        return <option value={obj}>{obj}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row mt__10">
                <div className="col-md-12 col-xs-12">
                  <div className="form-group text-left">
                    <label class="s-label6">Donor Preference</label>

                    <textarea
                      className="sform-control"
                      rows="3"
                      name="values[donor_prefs]"
                      id="donor_prefs"
                    />
                  </div>
                </div>
              </div>

              <div className="row mt__10">
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label class="s-label6">Nature of Fertillity Issues</label>
                    <input
                      type="text"
                      className="sform-control"
                      id="fertileproblem"
                      name="values[fertileproblem]"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="form-group text-left">
                    <label class="s-label6">
                      How soon do you need a donor?
                    </label>
                    <input
                      type="text"
                      className="sform-control"
                      name="values[donor_timeframe]"
                      id="donor_timeframe"
                      placeholder=""
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt__10">
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-register"
                  onClick={this.onClickSubmit.bind(this)}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }

  render_form() {
    return (
      <div className="form-box1">
        <form id="register-form">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <input
                type="email"
                className="sform-control"
                name="values[email]"
                id="email"
                placeholder="email address"
                required
              ></input>
            </div>
            <div className="col-md-6 col-xs-12"></div>

            <div className="col-md-6 col-xs-12">
              <input
                type="password"
                className="sform-control"
                name="values[password]"
                id="password"
                placeholder="password"
                required
              ></input>
            </div>

            <div className="col-md-6 col-xs-12">
              <input
                type="password"
                className="sform-control"
                name="values[confirm]"
                id="confirm"
                placeholder="confirm password"
                required
              ></input>
            </div>
            <div className="col-md-6 col-xs-12">
              <input
                type="name"
                className="sform-control"
                name="values[firstname]"
                id="firstname"
                placeholder="first name"
                required
              ></input>
            </div>
            <div className="col-md-6 col-xs-12">
              <input
                type="name"
                className="sform-control"
                name="values[lastname]"
                id="lastname"
                placeholder="last name"
                required
              ></input>
            </div>
          </div>

          <div className="row mt__30">
            <div className="col-12">
              <button type="submit" className="btn btn-register">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }

  render_description() {
    return (
      <div className="title-box1">
        <p className="s-label1">
          With more than 22 years of experience providing comprehensive services
          to the infertility community, we are the most experienced egg donation
          program in the world. Our program includes matching, screening, and
          coordination of all psychological, legal, medical, financial, and
          travel services.
        </p>
        <br></br>
        <p className="s-label1">
          From celebrities to first-time parents, all of our clients receive
          world-class, warm personal care and prompt attention to your needs. We
          work with recipients worldwide—couples, single men and women, and
          same-sex partners—and we are dedicated to providing you with the
          support and all the necessary information you need to feel comfortable
          during the process.
        </p>
        <br></br>
        <p className="s-label1">
          We adhere to the ASRM (American Society of Reproductive Medicine) and
          FDA guidelines and will always work to sustain the highest ethical
          standards.
        </p>
      </div>
    )
  }

  render_login() {
    return (
      <div className="title-box2">
        <div className="s-title2">Already Have An Account?</div>

        <a className="u-link" href="/login">
          Login Here
        </a>
      </div>
    )
  }

  render_content() {
    return (
      <div className="r-content">
        {this.render_header()}

        {this.render_newform()}

        {this.render_description()}

        {this.render_login()}
      </div>
    )
  }

  render_titlebox3() {
    return (
      <div className="title-box3">
        <p className="s-label2">
          By registering, you are giving us permission to contact you. This
          information is confidential and is never disclosed to third parties.
          Please check your spam folder and be sure to whitelist @eggdonor.com
          with your email provider to ensure all messages from us are received.
        </p>
      </div>
    )
  }

  render_confirm() {
    const { message } = this.state
    return (
      <div>
        <Header></Header>
        <div className="content-overlay">
          <div className="login-overlay">
            <div className="widget-message">{message}</div>
            <a className="u-link pt-5" href="/login">
              Go to login
            </a>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { confirm } = this.state

    if (confirm === true) {
      return this.render_confirm()
    }

    return (
      <div>
        <Header></Header>
        <ReactNotification ref={this.notificationDOMRef} />

        {this.render_content()}

        {this.render_titlebox3()}
      </div>
    )
  }
}

export default TRecipient_Register
