import React, { Component } from 'react'

import $ from 'jquery'
import queryString from 'query-string'
import Header from './header'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import './App.css'

class TPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      status: false,
    }
    this.code = ''
    this.encode_email = ''
    this.addNotification = this.addNotification.bind(this)
    this.notificationDOMRef = React.createRef()
  }

  componentWillMount() {
    //window.localStorage.clear();
    document.title = 'Change Password'

    var params = queryString.parse(window.location.search)
    if (params.code) this.code = params.code
    if (params.email) this.encode_email = params.email

    console.log('Code:' + this.code + ', Email:' + this.encode_email)
    if (this.code === '' || this.encode_email === '')
      this.setState({ message: 'Incorrect Link' })
  }

  componentDidMount() {}

  addNotification(type, msg) {
    this.notificationDOMRef.current.addNotification({
      title: '',
      message: msg,
      type: type,
      insert: 'top',
      container: 'bottom-center',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: { duration: 4000 },
      dismissable: { click: true },
    })
  }

  changePassword(e) {
    e.preventDefault()
    const form = e.target
    const data = new FormData(form)

    var email = data.get('email')
    var new_password = data.get('newpassword')
    var confirm_password = data.get('confirmpassword')

    if (email === '') {
      this.addNotification('danger', 'No Email field')
    }

    if (new_password === '' || confirm_password === '') {
      this.addNotification('danger', 'No Password value')
    }

    if (new_password !== confirm_password) {
      this.addNotification('danger', 'Password mismatch')
      return
    }

    var url = global.api_url + 'login/change_password'
    //window.localStorage.clear();

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: {
        'form[email]': data.get('email'),
        'form[password]': data.get('newpassword'),
        'form[verify_code]': this.code,
        'form[encode_email]': this.encode_email,
      },
      cache: false,
      crossDomain: true,
      success: function (rsp) {
        //this.addNotification('success', 'Your request has been sent');
        console.log('API rsp:' + rsp.message)
        if (rsp.success === true) {
          this.setState({ status: true, message: rsp.message })
        } else {
          this.addNotification('danger', rsp.message)
          $('#newpassword').val('')
          $('#confirmpassword').val('')
          $('#email').select()
        }
      }.bind(this),
    })
  }

  render() {
    console.log('Content render')
    const { message } = this.state
    if (message) {
      return (
        <div>
          <Header></Header>
          <ReactNotification ref={this.notificationDOMRef} />
          <div className="content-overlay">
            <div className="login-overlay">
              <div className="widget-message">{message}</div>
              <a className="u-link pt-5" href="/login">
                Go to login
              </a>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <Header></Header>
          <ReactNotification ref={this.notificationDOMRef} />
          <div className="content-overlay">
            <div className="login-overlay">
              <div className="login-widget">
                <div className="login-box">
                  <h1 className="title">NEW PASSWORD</h1>
                  <form
                    className="form-login"
                    onSubmit={this.changePassword.bind(this)}
                  >
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="username"
                        defaultValue=""
                      ></input>
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        name="newpassword"
                        id="newpassword"
                        placeholder="new password"
                        defaultValue=""
                      ></input>
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        name="confirmpassword"
                        id="confirmpassword"
                        placeholder="confirm password"
                        defaultValue=""
                      ></input>
                    </div>
                    <div className="form-group">
                      <input
                        type="submit"
                        value="Change Password"
                        className="btn btn-login"
                      ></input>
                    </div>
                  </form>
                  <a className="u-link text-left" href="/login">
                    Go to login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default TPassword
