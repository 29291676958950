import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import $ from 'jquery'
import Donor from './donor'
import Header from './header'
import * as F_UTIL from './helper'
import _ from 'lodash'
import Checkbox from 'rc-checkbox'
import 'rc-checkbox/assets/index.css'
import ReactPaginate from 'react-paginate'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import ScrollUpButton from 'react-scroll-up-button'
import Papa from 'papaparse'
import Select from 'react-select'
import './App.css'
import FILTER_STATES from './constants/FILTER_STATES'

let filter_states = FILTER_STATES

let data = []

class TSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      load_data: 0,
      total: 0, //total donors for this query
      active_page: 0,
      items_per_page: 10, //items per page
      total_count: 0, //loaded donors number
      page_range: 5, //pagination range

      donor_searching: 0,
      donor_searching_pending: 0,
      donor_searching_progress: 0,
      donor_loadmore: 0,
      filter_box: 0,
      sort_box: 0,
      sort_box_update: 0,

      frozen_open: 1,
      experience_open: 1,
      race_open: 1,
      hair_open: 1,
      eye_open: 0,
      height_open: 0,
      age_open: 0,
      blood_open: 0,
      education_open: 0,
      city: '',
      selectedState: { id: 0, value: '', lablel: 'All' },
      selectedCity: { id: 0, value: '', lablel: '' },
      age_min_options: F_UTIL.age_options,
      age_max_options: F_UTIL.age_options,
      filter_cities: [
        {
          id: 0,
          title: '-',
          selected: false,
          key: '',
        },
      ],
      sort_conditions: [
        {
          id: 1,
          title: 'Location',
          selected: false,
          key: 'location',
        },
        {
          id: 2,
          title: 'Recently added',
          selected: true,
          key: 'approved_date',
        },
        {
          id: 3,
          title: 'Age',
          selected: false,
          key: 'age',
        },
      ],

      sort_directions: 1,
      lat: 0,
      lng: 0,
      distance: 0,
      address: '',

      filter_age_min: {
        name: 'age[min]',
        value: '',
      },

      filter_age_max: {
        name: 'age[max]',
        value: '',
      },

      filter_height_min: {
        name: 'range[height][min]',
        value: '',
      },

      filter_height_max: {
        name: 'range[height][max]',
        value: '',
      },

      filter_distance: [
        {
          id: 1,
          title: '30 miles',
          selected: false,
          key: '30',
        },
        {
          id: 2,
          title: '50 miles',
          selected: false,
          key: '50',
        },
        {
          id: 3,
          title: '100 miles',
          selected: false,
          key: '100',
        },
        {
          id: 4,
          title: '100+ miles',
          selected: true,
          key: '0',
        },
      ],

      filter_freezeeggs: [
        {
          id: 1,
          title: 'Yes',
          value: '1',
          checked: false,
        },
        {
          id: 2,
          title: 'No',
          value: '0',
          checked: false,
        },
      ],

      filter_donorexperience: [
        {
          name: 'values[donatedbefore]',
          title: 'Prior Donor Experience',
          value: 'yes',
          checked: false,
        },
        {
          name: 'values[numchildren]',
          title: 'Prior Pregnancy',
          value: 'yes',
          checked: false,
        },
      ],

      filter_race: [
        {
          id: 1,
          title: 'African American',
          value: 'African American',
          checked: false,
        },
        {
          id: 2,
          title: 'Arab',
          value: 'Arab',
          checked: false,
        },
        {
          id: 3,
          title: 'Arab American',
          value: 'Arab American',
          checked: false,
        },
        {
          id: 4,
          title: 'Armenian',
          value: 'Armenian',
          checked: false,
        },
        {
          id: 5,
          title: 'Asian Indian',
          value: 'Asian Indian',
          checked: false,
        },
        {
          id: 6,
          title: 'Brazilian',
          value: 'Brazilian',
          checked: false,
        },
        {
          id: 7,
          title: 'British',
          value: 'British',
          checked: false,
        },
        {
          id: 8,
          title: 'Cambodian',
          value: 'Cambodian',
          checked: false,
        },
        {
          id: 9,
          title: 'Caucasian',
          value: 'Caucasian',
          checked: false,
        },
        {
          id: 10,
          title: 'Central American',
          value: 'Central American',
          checked: false,
        },
        {
          id: 11,
          title: 'Chinese',
          value: 'Chinese',
          checked: false,
        },
        {
          id: 12,
          title: 'Colombian',
          value: 'Colombian',
          checked: false,
        },
        {
          id: 13,
          title: 'Czech',
          value: 'Czech',
          checked: false,
        },
        {
          id: 14,
          title: 'Danish',
          value: 'Danish',
          checked: false,
        },
        {
          id: 15,
          title: 'Dutch',
          value: 'Dutch',
          checked: false,
        },
        {
          id: 16,
          title: 'Egyptian',
          value: 'Egyptian',
          checked: false,
        },
        {
          id: 17,
          title: 'English',
          value: 'English',
          checked: false,
        },
        {
          id: 18,
          title: 'Eurasian',
          value: 'Eurasian',
          checked: false,
        },
        {
          id: 39,
          title: 'Filipino',
          value: 'Filipino',
          checked: false,
        },
        {
          id: 19,
          title: 'French',
          value: 'French',
          checked: false,
        },
        {
          id: 20,
          title: 'German',
          value: 'German',
          checked: false,
        },
        {
          id: 21,
          title: 'Greek',
          value: 'Greek',
          checked: false,
        },
        {
          id: 22,
          title: 'Hawaiian/Pacific Islander',
          value: 'Hawaiian/Pacific Islander',
          checked: false,
        },
        {
          id: 23,
          title: 'Hispanic',
          value: 'Hispanic',
          checked: false,
        },
        {
          id: 24,
          title: 'Hungarian',
          value: 'Hungarian',
          checked: false,
        },
        {
          id: 25,
          title: 'Indonesian',
          value: 'Indonesian',
          checked: false,
        },
        {
          id: 26,
          title: 'Iranian',
          value: 'Iranian',
          checked: false,
        },
        {
          id: 27,
          title: 'Irish',
          value: 'Irish',
          checked: false,
        },
        {
          id: 28,
          title: 'Italian',
          value: 'Italian',
          checked: false,
        },
        {
          id: 29,
          title: 'Japanese',
          value: 'Japanese',
          checked: false,
        },
        {
          id: 30,
          title: 'Jewish',
          value: 'Jewish',
          checked: false,
        },
        {
          id: 31,
          title: 'Korean',
          value: 'Korean',
          checked: false,
        },
        {
          id: 32,
          title: 'Lebanese',
          value: 'Lebanese',
          checked: false,
        },
        {
          id: 33,
          title: 'Mexican',
          value: 'Mexican',
          checked: false,
        },
        {
          id: 34,
          title: 'Middle Eastern',
          value: 'Middle Eastern',
          checked: false,
        },
        {
          id: 35,
          title: 'Native American',
          value: 'Native American',
          checked: false,
        },
        {
          id: 36,
          title: 'Other European',
          value: 'Other European',
          checked: false,
        },
        {
          id: 37,
          title: 'Pakastani',
          value: 'Pakastani',
          checked: false,
        },
        {
          id: 38,
          title: 'Persian',
          value: 'Persian',
          checked: false,
        },

        {
          id: 40,
          title: 'Polish',
          value: 'Polish',
          checked: false,
        },
        {
          id: 41,
          title: 'Portugese',
          value: 'Portugese',
          checked: false,
        },
        {
          id: 42,
          title: 'Puerto Rican',
          value: 'Puerto Rican',
          checked: false,
        },
        {
          id: 43,
          title: 'Romanian',
          value: 'Romanian',
          checked: false,
        },
        {
          id: 44,
          title: 'Russian',
          value: 'Russian',
          checked: false,
        },
        {
          id: 45,
          title: 'South American',
          value: 'South American',
          checked: false,
        },
        {
          id: 46,
          title: 'Spanish',
          value: 'Spanish',
          checked: false,
        },
        {
          id: 47,
          title: 'Swedish',
          value: 'Swedish',
          checked: false,
        },
        {
          id: 48,
          title: 'Syrian',
          value: 'Syrian',
          checked: false,
        },
        {
          id: 49,
          title: 'Thai',
          value: 'Thai',
          checked: false,
        },
        {
          id: 50,
          title: 'Turkish',
          value: 'Turkish',
          checked: false,
        },
        {
          id: 51,
          title: 'Ukranian',
          value: 'Ukranian',
          checked: false,
        },
        {
          id: 52,
          title: 'Vietnamese',
          value: 'Vietnamese',
          checked: false,
        },
      ],

      filter_haircolor: [
        {
          id: 1,
          title: 'Light Brown',
          value: 'Lt. Brown',
          checked: false,
        },
        {
          id: 2,
          title: 'Medium Brown',
          value: 'Md. Brown',
          checked: false,
        },
        {
          id: 3,
          title: 'Dark Brown',
          value: 'Dk. Brown',
          checked: false,
        },
        {
          id: 4,
          title: 'Red Brown',
          value: 'Red Brown',
          checked: false,
        },
        {
          id: 5,
          title: 'Blonde',
          value: 'Blonde',
          checked: false,
        },
        {
          id: 6,
          title: 'Strawberry Blonde',
          value: 'Strawberry Blonde',
          checked: false,
        },
        {
          id: 7,
          title: 'Black',
          value: 'Black',
          checked: false,
        },
      ],

      filter_eyecolor: [
        {
          id: 1,
          title: 'Brown',
          value: 'Brown',
          checked: false,
        },
        {
          id: 2,
          title: 'Blue',
          value: 'Blue',
          checked: false,
        },
        {
          id: 3,
          title: 'Green',
          value: 'Green',
          checked: false,
        },
        {
          id: 4,
          title: 'Hazel',
          value: 'Hazel',
          checked: false,
        },
        {
          id: 5,
          title: 'Gray',
          value: 'Gray',
          checked: false,
        },
      ],

      filter_bloodtype: [
        {
          id: 0,
          title: 'A+',
          value: 'A+',
          checked: false,
        },
        {
          id: 1,
          title: 'A-',
          value: 'A-',
          checked: false,
        },
        {
          id: 2,
          title: 'B+',
          value: 'B+',
          checked: false,
        },
        {
          id: 4,
          title: 'B-',
          value: 'B-',
          checked: false,
        },
        {
          id: 5,
          title: 'O+',
          value: 'O+',
          checked: false,
        },
        {
          id: 6,
          title: 'O-',
          value: 'O-',
          checked: false,
        },
        {
          id: 7,
          title: 'AB+',
          value: 'AB',
          checked: false,
        },
        {
          id: 8,
          title: 'AB-',
          value: 'AB-',
          checked: false,
        },
      ],

      filter_educationlevel: [
        {
          id: 1,
          title: 'High School Graduate',
          value: 'Highschool Graduate',
          checked: false,
        },
        {
          id: 2,
          title: 'Currently in College',
          value: 'Currently in College',
          checked: false,
        },
        {
          id: 3,
          title: 'Occupational School',
          value: 'Occupational School',
          checked: false,
        },
        {
          id: 4,
          title: 'Technical College',
          value: 'Technical College',
          checked: false,
        },
        {
          id: 5,
          title: 'Associate Degree',
          value: 'Associate Degree',
          checked: false,
        },
        {
          id: 6,
          title: 'Bachelor Degree',
          value: 'Bachelor Degree',
          checked: false,
        },
        {
          id: 7,
          title: "Master's Degree",
          value: "Master's Degree",
          checked: false,
        },
        {
          id: 8,
          title: 'Ph.D. or M.D. Degree',
          value: 'Ph.D. or M.D. Degree',
          checked: false,
        },
        {
          id: 9,
          title: 'Other Doctoral Program',
          value: 'Other Doctoral Program',
          checked: false,
        },
        {
          id: 10,
          title: 'Other Education',
          value: 'Other Education',
          checked: false,
        },
      ],
      cityLoaded: false,
      donors: [],
    }
    this.p_info = ''
    this.p_rid = global.r_id
    this.api_message = ''
    this.filters = ''

    this.handleKeyword = this.handleKeyword.bind(this)
    this.handleKeyCity = this.handleKeyCity.bind(this)
    this.handleChangeCityInput = this.handleChangeCityInput.bind(this)
    this.addNotification = this.addNotification.bind(this)
    this.notificationDOMRef = React.createRef()
    this.getData = this.getData.bind(this)
  }

  componentWillMount() {
    //var params = queryString.parse(this.props.props.location.search);
    //this.p_rid = params.id;

    F_UTIL.check_login()
    F_UTIL.check_account_type()
    this.loadLocationSettings()
    this.loadSortSettings()
    this.getSearchValues()
    document.title = global.title
    //this.getCsvData();
  }

  fetchCsv() {
    return fetch('/csv/cities.csv').then(function (response) {
      let reader = response.body.getReader()
      let decoder = new TextDecoder('utf-8')

      return reader.read().then(function (result) {
        return decoder.decode(result.value)
      })
    })
  }

  getData(result) {
    data = [...result.data]
    this.setState({ cityLoaded: true })
  }

  async getCsvData() {
    let csvData = await this.fetchCsv()

    Papa.parse(csvData, {
      complete: this.getData,
    })
  }

  componentDidMount() {
    /*
    const script = document.createElement("script");

    script.src = "https://maps.googleapis.com/maps/api/js?key=" + global.map_key + "&libraries=places";
    script.async = false;
    document.body.appendChild(script);
    $('#autocomplete').focus();
    */
  }

  loadLocationSettings() {
    const { filter_distance } = this.state
    let settings = F_UTIL.load_location_settings()
    if (settings) {
      let temp = JSON.parse(JSON.stringify(this.state.filter_distance))
      temp.forEach((item) => {
        if (item.key === settings.distance) item.selected = true
        else item.selected = false
      })
      this.setState({
        filter_distance: temp,
        distance: settings.distance,
        lat: settings.lat,
        lng: settings.lng,
      })
    }
  }

  loadSortSettings() {
    const { sort_conditions, sort_directions } = this.state
    let settings = F_UTIL.load_sort_settings()
    if (settings) {
      sort_conditions.forEach((item) => {
        if (item.key === settings.sortby) item.selected = true
        else item.selected = false
      })

      this.setState({
        sort_conditions: sort_conditions,
        sort_directions: settings.dir,
      })
    }
  }

  addNotification(type, msg) {
    this.notificationDOMRef.current.addNotification({
      message: msg,
      type: type,
      insert: 'top',
      container: 'bottom-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
    })
  }

  searchDonors(loadmore = false) {
    const {
      donor_searching_progress,
      active_page,
      items_per_page,
      lat,
      lng,
      distance,
      total_count,
      donors,
      sort_conditions,
      sort_directions,
      city,
      selectedState,
    } = this.state

    if (donor_searching_progress > 0) {
      return
    }

    if (loadmore) {
      this.setState({ donor_searching_progress: 1, donor_loadmore: 1 })
    } else {
      this.setState({ donor_searching_progress: 1 })
    }

    let data = []

    if (isMobile) {
      data = $('#donors-search-form-mobile').serializeArray()
    } else {
      data = $('#donors-search-form').serializeArray()
    }

    // data = data.filter((item, index) => {
    //   if (item.value === '') {
    //     return false
    //   } else {
    //     return true
    //   }
    // })

    let keyword = $('#keyword').val()
    let offset = total_count

    data.push({ name: 'token', value: global.token })
    data.push({ name: 'values[keyword]', value: keyword })

    //location info
    data.push({ name: 'state', value: selectedState.value })
    data.push({ name: 'city', value: city })

    /*
    data.push({name: 'lat', value: lat});
    data.push({name: 'lng', value: lng});
    data.push({name: 'distance', value: distance});
    */
    data.push({ name: 'page', value: active_page })
    data.push({ name: 'offset', value: offset })
    data.push({ name: 'items', value: items_per_page })

    var sortby = 'approved_date'
    sort_conditions.forEach((item) => {
      if (item.selected === true) {
        sortby = item.key
      }
    })

    data.push({ name: 'sortby', value: sortby })

    let dir = 'DESC'
    if (sort_directions > 0) {
      dir = 'ASC'
    }
    data.push({ name: 'dir', value: dir })

    if (loadmore === false) {
      F_UTIL.save_location_settings(
        $('#autocomplete').val(),
        lat,
        lng,
        distance,
      )
      F_UTIL.save_sort_settings(sortby, sort_directions)
    }

    var url = global.api_url + 'search/search/' + global.r_id

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: data,
      cache: false,
      crossDomain: true,
      success: function (rsp) {
        // console.log('Donor Search Ajax Finished')
        if (rsp.success === true) {
          offset += rsp.count
          if (loadmore) {
            //this.donors = response.donors;
            for (let i = 0; i < rsp.donors.length; i++) {
              donors.push(rsp.donors[i])
            }
            this.setState({
              donors: donors,
              total: rsp.total,
              total_count: offset,
              donor_loadmore: 0,
            })
          } else {
            this.setState({
              donors: rsp.donors,
              total: rsp.total,
              total_count: offset,
            })
          }
        } else if (rsp.credential === false) {
          F_UTIL.goto_login()
        } else {
          this.api_message = rsp.message
          this.addNotification('danger', rsp.message)
        }

        const { donor_searching, donor_searching_pending } = this.state
        // console.log(
        //   'Search status, Search:' +
        //     donor_searching +
        //     ', Pending:' +
        //     donor_searching_pending,
        // )
        if (donor_searching_pending > 0) {
          // console.log('Reset Search flag')
          this.setState({
            donor_searching: 1,
            donor_searching_pending: 0,
            donor_searching_progress: 0,
            total_count: 0,
          })
        } else {
          // console.log('Clear Search flag. Display:' + rsp.count)
          this.setState({
            donor_searching: 0,
            donor_searching_pending: 0,
            donor_searching_progress: 0,
          })
        }
      }.bind(this),
    })
  }

  initFilters() {
    // console.log('Query:' + this.filters)
    const {
      filter_freezeeggs,
      filter_donorexperience,
      filter_race,
      filter_haircolor,
      filter_eyecolor,
      filter_height_max,
      filter_height_min,
      filter_age_min,
      filter_age_max,
      filter_bloodtype,
      filter_educationlevel,
    } = this.state

    //keyword
    if (this.filters.keyword) {
      $('#keyword').val(this.filters.keyword)
    }

    //freezeeggs
    if (this.filters.freezeeggs) {
      filter_freezeeggs.forEach((ref, i) => {
        this.filters.freezeeggs.forEach((item) => {
          if (item === filter_freezeeggs[i].value) {
            filter_freezeeggs[i].checked = true
          }
        })
      })
    }
    //donate before
    if (this.filters.donatedbefore) {
      filter_donorexperience[0].checked = true
    }

    //numchildren
    if (this.filters.numchildren) {
      filter_donorexperience[1].checked = true
    }

    //race
    if (this.filters.race) {
      filter_race.forEach((ref, i) => {
        this.filters.race.forEach((item) => {
          if (item === filter_race[i].value) {
            filter_race[i].checked = true
          }
        })
      })
    }

    //hair color
    if (this.filters.haircolor) {
      filter_haircolor.forEach((ref, i) => {
        this.filters.haircolor.forEach((item) => {
          if (item === filter_haircolor[i].value) {
            filter_haircolor[i].checked = true
          }
        })
      })
    }

    //eye color
    if (this.filters.eyecolor) {
      filter_eyecolor.forEach((ref, i) => {
        this.filters.eyecolor.forEach((item) => {
          if (item === filter_eyecolor[i].value) {
            filter_eyecolor[i].checked = true
          }
        })
      })
    }

    //height
    if (this.filters.height) {
      if (this.filters.height.min) {
        filter_height_min.value = this.filters.height.min
      }

      if (this.filters.height.max) {
        filter_height_max.value = this.filters.height.max
      }
    }

    //age
    if (this.filters.age) {
      if (this.filters.age.min) filter_age_min.value = this.filters.age.min
      if (this.filters.age.max) filter_age_max.value = this.filters.age.max
    }

    //bloodtype
    if (this.filters.bloodtype) {
      filter_bloodtype.forEach((ref, i) => {
        this.filters.bloodtype.forEach((item) => {
          if (item === filter_bloodtype[i].value) {
            filter_bloodtype[i].checked = true
          }
        })
      })
    }
    //education level
    if (this.filters.educationlevel) {
      filter_educationlevel.forEach((ref, i) => {
        this.filters.educationlevel.forEach((item) => {
          if (item === filter_educationlevel[i].value) {
            filter_educationlevel[i].checked = true
          }
        })
      })
    }

    this.setState({
      filter_freezeeggs: filter_freezeeggs,
      filter_donorexperience: filter_donorexperience,
      filter_race: filter_race,
      filter_haircolor: filter_haircolor,
      filter_eyecolor: filter_eyecolor,
      filter_height_max: filter_height_max,
      filter_height_min: filter_height_min,
      filter_age_max: filter_age_max,
      filter_age_min: filter_age_min,
      filter_bloodtype: filter_bloodtype,
      filter_educationlevel: filter_educationlevel,
    })
  }

  getSearchValues() {
    var url = global.api_url + 'search/getsavedsearch/' + global.r_id
    // console.log('Call getSavedSearch', { url })

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: { token: global.token },
      cache: false,
      crossDomain: true,
      success: function (rsp) {
        this.setState({ load_data: 1 })
        if (rsp.success) {
          this.filters = rsp.filters
          this.initFilters()
        }
        this.needSearch()
      }.bind(this),
    })
  }

  render_loading() {
    return (
      <div>
        <Header></Header>
        <div id="loading"></div>
      </div>
    )
  }

  render_error() {
    return (
      <div>
        <Header></Header>
        <div className="content">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="account-heading text-center">
                  {this.api_message}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  changeFilterHeightMin(e) {
    var index = $(e.target).prop('selectedIndex')
    var selected_value = $(e.target).val()

    const { filter_height_min } = this.state

    filter_height_min.value = selected_value
    this.setState({
      filter_height_min: filter_height_min,
      active_page: 0,
      total_count: 0,
    })

    console.log('Height Min Selected: ' + selected_value)

    this.needSearch()
  }

  changeFilterHeightMax(e) {
    var index = $(e.target).prop('selectedIndex')
    var selected_value = $(e.target).val()

    const { filter_height_max } = this.state

    filter_height_max.value = selected_value
    this.setState({
      filter_height_max: filter_height_max,
      active_page: 0,
      total_count: 0,
    })

    console.log('Height Max Selected: ' + selected_value)

    this.needSearch()
  }

  changeFilterAgeMin(e) {
    var index = $(e.target).prop('selectedIndex')
    var selected_value = $(e.target).val()

    const { filter_age_min } = this.state

    filter_age_min.value = selected_value
    const newMaxAgeOptions = F_UTIL.age_options.filter((it) => {
      if (it.value === '' || it.value >= selected_value) {
        return true
      }
    })
    this.setState({
      filter_age_min: filter_age_min,
      active_page: 0,
      total_count: 0,
      age_max_options: newMaxAgeOptions,
    })

    console.log('Age Min Selected: ' + selected_value)

    this.needSearch()
  }

  changeFilterAgeMax(e) {
    var index = $(e.target).prop('selectedIndex')
    var selected_value = $(e.target).val()

    const { filter_age_max } = this.state

    filter_age_max.value = selected_value
    const newMinAgeOptions = F_UTIL.age_options.filter((it) => {
      if (it.value === '' || it.value <= selected_value) {
        return true
      }
    })

    this.setState({
      filter_age_max: filter_age_max,
      active_page: 0,
      total_count: 0,
      age_min_options: newMinAgeOptions,
    })

    console.log('Age Max Selected: ' + selected_value)

    this.needSearch()
  }

  changeFilterFreezeeggs(ref, e) {
    console.log('Filter FreezeEggs Clicked:' + ref)

    let filters = this.state.filter_freezeeggs

    console.log(
      'Filter FreezeEggs Clicked:index:' +
        ref +
        ', Title:' +
        filters[ref].title +
        ', Value:' +
        filters[ref].checked,
    )

    filters[ref].checked = !filters[ref].checked
    this.setState({
      filter_freezeeggs: filters,
      active_page: 0,
      total_count: 0,
    })
    this.needSearch()
  }

  changeFilterDonorExperience(ref, e) {
    console.log('Filter DonorExperience Clicked:' + ref)

    let filters = this.state.filter_donorexperience

    console.log(
      'Filter DonorExperience Clicked:index:' +
        ref +
        ', Title:' +
        filters[ref].title +
        ', Value:' +
        filters[ref].checked,
    )

    filters[ref].checked = !filters[ref].checked
    this.setState({
      filter_donorexperience: filters,
      active_page: 0,
      total_count: 0,
    })
    this.needSearch()
  }

  changeFilterRace(ref, e) {
    // console.log('Filter Race Clicked:' + ref)

    let filters = this.state.filter_race

    // console.log(
    //   'Filter Race Clicked:index:' +
    //     ref +
    //     ', Title:' +
    //     filters[ref].title +
    //     ', Value:' +
    //     filters[ref].checked,
    // )

    filters[ref].checked = !filters[ref].checked
    this.setState({ filter_race: filters, active_page: 0, total_count: 0 })
    this.needSearch()
  }

  changeFilterHairColor(ref, e) {
    console.log('Filter HairColor Clicked:' + ref)

    let filters = this.state.filter_haircolor

    console.log(
      'Filter HairColor Clicked:index:' +
        ref +
        ', Title:' +
        filters[ref].title +
        ', Value:' +
        filters[ref].checked,
    )

    filters[ref].checked = !filters[ref].checked
    this.setState({ filter_haircolor: filters, active_page: 0, total_count: 0 })
    this.needSearch()
  }

  changeFilterEyeColor(ref, e) {
    console.log('Filter EyeColor Clicked:' + ref)

    let filters = this.state.filter_eyecolor

    console.log(
      'Filter EyeColor Clicked:index:' +
        ref +
        ', Title:' +
        filters[ref].title +
        ', Value:' +
        filters[ref].checked,
    )

    filters[ref].checked = !filters[ref].checked
    this.setState({ filter_eyecolor: filters, active_page: 0, total_count: 0 })
    this.needSearch()
  }

  changeFilterBloodType(ref, e) {
    console.log('Filter Bllodtype Clicked:' + ref)

    let filters = this.state.filter_bloodtype

    console.log(
      'Filter Bllodtype Clicked:index:' +
        ref +
        ', Title:' +
        filters[ref].title +
        ', Value:' +
        filters[ref].checked,
    )

    filters[ref].checked = !filters[ref].checked
    this.setState({ filter_bloodtype: filters, active_page: 0, total_count: 0 })
    this.needSearch()
  }

  changeFilterEducationLevel(ref, e) {
    console.log('Filter EducationLevel Clicked:' + ref)

    let filters = this.state.filter_educationlevel

    console.log(
      'Filter EducationLevel Clicked:index:' +
        ref +
        ', Title:' +
        filters[ref].title +
        ', Value:' +
        filters[ref].checked,
    )

    filters[ref].checked = !filters[ref].checked
    this.setState({
      filter_educationlevel: filters,
      active_page: 0,
      total_count: 0,
    })
    this.needSearch()
  }

  onFocusAutoComplete(e) {
    e.preventDefault()
    console.log('Focused Autocomplete')
    $('#autocomplete').select()
  }

  handleLocation(e) {
    e.preventDefault()
    if (e.keyCode === 13) {
      // Do something
      return false
    }
  }

  render_location() {
    const { city, selectedState, selectedCity } = this.state
    //const defaultValue = {value: '', label: 'All', id:0};
    return (
      <div className="cta-widget cta-location">
        <div className="widget-subtitle small">Searching in</div>

        <div className="cta-section-location">
          <div className="section-title">State</div>
          <Select
            value={selectedState}
            defaultValue={selectedState}
            defaultInputValue="All"
            onChange={this.handleChangeState}
            options={filter_states}
          />
        </div>

        <div className="cta-section-location">
          <div className="section-title">City</div>
          <input
            type="text"
            className="input-location"
            placeholder="Search by City"
            defaultValue={city}
            value={city}
            onKeyUp={this.handleKeyCity}
            onChange={this.handleChangeCityInput}
          />
        </div>
      </div>
    )
  }

  needSearch() {
    const { donor_searching } = this.state
    // console.log('Change Filter Select')
    if (donor_searching > 0) {
      // console.log('Set Pending flag')
      this.setState({ donor_searching_pending: 1 })
    } else {
      // console.log('Set Searching flag')
      this.setState({ donor_searching: 1 })
    }
    this.setState({ total: 0 })
  }

  clearFilters(e) {
    // console.log('Clear Filters')

    const {
      filter_freezeeggs,
      filter_donorexperience,
      filter_race,
      filter_haircolor,
      filter_eyecolor,
      filter_height_max,
      filter_height_min,
      filter_age_min,
      filter_age_max,
      filter_bloodtype,
      filter_educationlevel,
    } = this.state

    $('#keyword').val('')
    //frozen eggs
    filter_freezeeggs.forEach((ref, i) => {
      filter_freezeeggs[i].checked = false
    })

    //donor experience
    filter_donorexperience.forEach((ref, i) => {
      filter_donorexperience[i].checked = false
    })

    //race
    filter_race.forEach((ref, i) => {
      filter_race[i].checked = false
    })

    //hair color
    filter_haircolor.forEach((ref, i) => {
      filter_haircolor[i].checked = false
    })

    //eye color
    filter_eyecolor.forEach((ref, i) => {
      filter_eyecolor[i].checked = false
    })

    //height
    filter_height_min.value = ''
    filter_height_max.value = ''

    //age
    filter_age_max.value = ''
    filter_age_min.value = ''

    //blood type
    filter_bloodtype.forEach((ref, i) => {
      filter_bloodtype[i].checked = false
    })

    //education level
    filter_educationlevel.forEach((ref, i) => {
      filter_educationlevel[i].checked = false
    })

    this.setState({
      filter_freezeeggs: filter_freezeeggs,
      filter_donorexperience: filter_donorexperience,
      filter_race: filter_race,
      filter_haircolor: filter_haircolor,
      filter_eyecolor: filter_eyecolor,
      filter_height_max: filter_height_max,
      filter_height_min: filter_height_min,
      filter_age_max: filter_age_max,
      filter_age_min: filter_age_min,
      filter_bloodtype: filter_bloodtype,
      filter_educationlevel: filter_educationlevel,
      selectedCity: { id: 0, value: '', label: '-' },
      selectedState: { id: 0, value: '', label: 'All' },
      filter_cities: [],
      active_page: 0,
      total_count: 0,
    })
    $('.rc-checkbox-input').prop('checked', false)
    $('select.search-value').val('')
    this.needSearch()
  }

  render_search_counts() {
    const { total } = this.state
    return (
      <div className="cta-widget cta-result">
        <div className="widget-result">{total} Results</div>

        <div className="widget-clear" onClick={this.clearFilters.bind(this)}>
          Clear Filters
        </div>
      </div>
    )
  }

  onClickFilterFrozen(e) {
    const { frozen_open } = this.state
    this.setState({ frozen_open: frozen_open ? 0 : 1 })

    $('.cta-frozen .cta-content').slideToggle('fast')
  }

  render_filter_frozen() {
    const { filter_freezeeggs, frozen_open } = this.state
    var _this = this

    return (
      <div className="cta-widget cta-frozen">
        <div
          className="widget-title"
          onClick={this.onClickFilterFrozen.bind(this)}
        >
          <span>Frozen Eggs</span>
          {frozen_open ? (
            <i className="fa fa-minus pull-right" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-plus pull-right" aria-hidden="true"></i>
          )}
        </div>

        <div className="cta-content">
          {filter_freezeeggs.map(function (obj, i) {
            return (
              <div
                className="cta-checkbox"
                key={`filter-freeze-eggs-${obj.value}`}
              >
                <Checkbox
                  checked={obj.checked}
                  name="values[freezeeggs][]"
                  value={obj.value}
                  onChange={_this.changeFilterFreezeeggs.bind(_this, i)}
                />
                &nbsp;&nbsp;{obj.title}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  onClickFilterExperience(e) {
    const { experience_open } = this.state
    this.setState({ experience_open: experience_open ? 0 : 1 })

    $('.cta-experience .cta-content').slideToggle('fast')
  }

  render_filter_experience() {
    const { filter_donorexperience, experience_open } = this.state
    var _this = this
    return (
      <div className="cta-widget cta-experience">
        <div
          className="widget-title"
          onClick={this.onClickFilterExperience.bind(this)}
        >
          <span>Donor Experience</span>
          {experience_open ? (
            <i className="fa fa-minus pull-right" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-plus pull-right" aria-hidden="true"></i>
          )}
        </div>

        <div className="cta-content">
          {filter_donorexperience.map((obj, i) => {
            return (
              <div
                className="cta-checkbox"
                key={`filter-donor-experience-${obj.title}`}
              >
                <Checkbox
                  checked={obj.checked}
                  name={obj.name}
                  value={obj.value}
                  onChange={_this.changeFilterDonorExperience.bind(_this, i)}
                />
                &nbsp;&nbsp;{obj.title}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  changeRaceSelects(e) {
    console.log('Mutitple select clicked')
    this.needSearch()
  }

  changeRaceSelect(ref, e) {
    console.log('Race select: ' + ref)
  }

  onClickFilterRace(e) {
    const { race_open } = this.state
    this.setState({ race_open: race_open ? 0 : 1 })

    $('.cta-race .cta-content').slideToggle('fast')
  }

  render_filter_race() {
    const { race_open, filter_race } = this.state
    var _this = this

    return (
      <div className="cta-widget cta-race">
        <div
          className="widget-title"
          onClick={this.onClickFilterRace.bind(this)}
        >
          <span>Ethinicity</span>
          {race_open ? (
            <i className="fa fa-minus pull-right" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-plus pull-right" aria-hidden="true"></i>
          )}
        </div>

        <div className="cta-content">
          {filter_race.map(function (obj, i) {
            return (
              <div className="cta-checkbox" key={`filter-race-${obj.value}`}>
                <Checkbox
                  key={i}
                  checked={obj.checked}
                  name="race[]"
                  value={obj.value}
                  onChange={_this.changeFilterRace.bind(_this, i)}
                />
                &nbsp;&nbsp;{obj.title}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  onClickFilterHair(e) {
    const { hair_open } = this.state
    this.setState({ hair_open: hair_open ? 0 : 1 })

    $('.cta-hair .cta-content').slideToggle('fast')
  }

  render_filter_haircolor() {
    const { filter_haircolor, hair_open } = this.state
    var _this = this
    return (
      <div className="cta-widget cta-hair">
        <div
          className="widget-title"
          onClick={this.onClickFilterHair.bind(this)}
        >
          <span>Hair Color</span>
          {hair_open ? (
            <i className="fa fa-minus pull-right" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-plus pull-right" aria-hidden="true"></i>
          )}
        </div>
        <div className="cta-content">
          {filter_haircolor.map(function (obj, i) {
            return (
              <div
                className="cta-checkbox"
                key={`filter-hair-color-${obj.value}`}
              >
                <Checkbox
                  checked={obj.checked}
                  name="values[haircolor][]"
                  value={obj.value}
                  onChange={_this.changeFilterHairColor.bind(_this, i)}
                />
                &nbsp;&nbsp;{obj.title}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  onClickFilterEye(e) {
    const { eye_open } = this.state
    this.setState({ eye_open: eye_open ? 0 : 1 })

    $('.cta-eye .widget-content').slideToggle('fast')
  }

  render_filter_eyecolor() {
    const { filter_eyecolor, eye_open } = this.state
    var _this = this

    return (
      <div className="cta-widget cta-eye">
        <div
          className="widget-title"
          onClick={this.onClickFilterEye.bind(this)}
        >
          <span className="">Eye Color</span>
          {eye_open ? (
            <i className="fa fa-minus pull-right" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-plus pull-right" aria-hidden="true"></i>
          )}
        </div>
        <div className="widget-content closed">
          {filter_eyecolor.map(function (obj, i) {
            return (
              <div
                className="cta-checkbox"
                key={`filter-eye-color-${obj.value}`}
              >
                <Checkbox
                  key={i}
                  checked={obj.checked}
                  name="values[eyecolor][]"
                  value={obj.value}
                  onChange={_this.changeFilterEyeColor.bind(_this, i)}
                />
                &nbsp;&nbsp;{obj.title}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  onClickFilterHeight(e) {
    const { height_open } = this.state
    this.setState({ height_open: height_open ? 0 : 1 })

    $('.cta-height .widget-content').slideToggle('fast')
  }

  render_filter_height() {
    const { height_open, filter_height_min, filter_height_max } = this.state
    return (
      <div className="cta-widget cta-height">
        <div
          className="widget-title"
          onClick={this.onClickFilterHeight.bind(this)}
        >
          <span className="">Height</span>
          {height_open ? (
            <i className="fa fa-minus pull-right" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-plus pull-right" aria-hidden="true"></i>
          )}
        </div>
        <div className="widget-content closed">
          <div className="min-height">
            <select
              name={filter_height_min.name}
              id="height_min"
              className="form-control search-value"
              value={filter_height_min.value}
              onChange={this.changeFilterHeightMin.bind(this, 1)}
            >
              {F_UTIL.height_options.map(function (obj, i) {
                return (
                  <option
                    value={obj.value}
                    key={`height-options-min-${obj.value}`}
                  >
                    {obj.title}
                  </option>
                )
              })}
            </select>
          </div>
          -
          <div className="max-height">
            <select
              name={filter_height_max.name}
              id="height_max"
              className="form-control search-value"
              value={filter_height_max.value}
              onChange={this.changeFilterHeightMax.bind(this, 2)}
            >
              {F_UTIL.height_options.map(function (obj, i) {
                return (
                  <option
                    value={obj.value}
                    key={`filter-height-options-max-${obj.value}`}
                  >
                    {obj.title}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
    )
  }

  onClickFilterAge(e) {
    const { age_open } = this.state
    this.setState({ age_open: age_open ? 0 : 1 })

    $('.cta-age .widget-content').slideToggle('fast')
  }

  render_filter_age() {
    const { age_open, filter_age_min, filter_age_max } = this.state
    return (
      <div className="cta-widget cta-age">
        <div
          className="widget-title"
          onClick={this.onClickFilterAge.bind(this)}
        >
          <span className="">Age</span>
          {age_open ? (
            <i className="fa fa-minus pull-right" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-plus pull-right" aria-hidden="true"></i>
          )}
        </div>
        <div className="widget-content closed">
          <div className="min-age">
            <select
              name={filter_age_min.name}
              id="age-min"
              className="form-control search-value"
              value={filter_age_min.value}
              onChange={this.changeFilterAgeMin.bind(this)}
            >
              {this.state.age_min_options.map(function (obj, i) {
                return (
                  <option
                    value={obj.value}
                    key={`age-options-min-${obj.value}`}
                  >
                    {obj.title}
                  </option>
                )
              })}
            </select>
          </div>
          -
          <div className="max-age">
            <select
              name={filter_age_max.name}
              id="age-max"
              className="form-control search-value"
              value={filter_age_max.value}
              onChange={this.changeFilterAgeMax.bind(this)}
            >
              {this.state.age_max_options.map(function (obj, i) {
                return (
                  <option
                    value={obj.value}
                    key={`age-options-max-${obj.value}`}
                  >
                    {obj.title}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
    )
  }

  onClickFilterBlood(e) {
    const { blood_open } = this.state
    this.setState({ blood_open: blood_open ? 0 : 1 })

    $('.cta-blood .widget-content').slideToggle('fast')
  }

  render_filter_blood() {
    const { filter_bloodtype, blood_open } = this.state
    var _this = this

    return (
      <div className="cta-widget cta-blood">
        <div
          className="widget-title"
          onClick={this.onClickFilterBlood.bind(this)}
        >
          <span className="">Blood Type</span>
          {blood_open ? (
            <i className="fa fa-minus pull-right" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-plus pull-right" aria-hidden="true"></i>
          )}
        </div>

        <div className="widget-content closed">
          {filter_bloodtype.map(function (obj, i) {
            return (
              <div
                className="cta-checkbox"
                key={`filter-blood-type-${obj.value}`}
              >
                <Checkbox
                  key={i}
                  checked={obj.checked}
                  name="values[bloodtype][]"
                  value={obj.value}
                  onChange={_this.changeFilterBloodType.bind(_this, i)}
                />
                &nbsp;&nbsp;{obj.title}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  onClickFilterEducation(e) {
    const { education_open } = this.state
    this.setState({ education_open: education_open ? 0 : 1 })

    $('.cta-education .widget-content').slideToggle('fast')
  }

  render_filter_education() {
    const { filter_educationlevel, education_open } = this.state
    var _this = this

    return (
      <div className="cta-widget cta-education">
        <div
          className="widget-title"
          onClick={this.onClickFilterEducation.bind(this)}
        >
          <span className="">Education</span>
          {education_open ? (
            <i className="fa fa-minus pull-right" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-plus pull-right" aria-hidden="true"></i>
          )}
        </div>

        <div className="widget-content closed">
          {filter_educationlevel.map(function (obj, i) {
            return (
              <div
                className="cta-checkbox"
                key={`filter-education-level-${obj.value}`}
              >
                <Checkbox
                  key={i}
                  checked={obj.checked}
                  name="educationlevel[]"
                  value={obj.value}
                  onChange={_this.changeFilterEducationLevel.bind(_this, i)}
                />
                &nbsp;&nbsp;{obj.title}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  render_cta() {
    return (
      <div className="col-xs-12 col-md-3">
        {this.render_location()}
        <form id="donors-search-form" method="post">
          {this.render_search_counts()}

          {this.render_filter_frozen()}

          {this.render_filter_experience()}

          {this.render_filter_race()}

          {this.render_filter_haircolor()}

          {this.render_filter_eyecolor()}

          {this.render_filter_height()}

          {this.render_filter_age()}

          {this.render_filter_blood()}

          {this.render_filter_education()}
        </form>
      </div>
    )
  }

  closeBox(e) {
    this.setState({ filter_box: 0, sort_box: 0 })
  }

  render_filter_box() {
    const { filter_box } = this.state
    return (
      <div className="box-widget filter-box" hidden={!filter_box}>
        <form id="donors-search-form-mobile" method="post">
          <div className="box-title">FILTER</div>

          <div className="box-close" onClick={this.closeBox.bind(this)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>

          <div className="box-clear" onClick={this.clearFilters.bind(this)}>
            Clear
          </div>

          {this.render_filter_frozen()}

          {this.render_filter_experience()}

          {this.render_filter_race()}

          {this.render_filter_haircolor()}

          {this.render_filter_eyecolor()}

          {this.render_filter_height()}

          {this.render_filter_age()}

          {this.render_filter_blood()}

          {this.render_filter_education()}
        </form>
      </div>
    )
  }

  clickMobileSort(id, e) {
    console.log('Mobile Sort clicked:' + id)
    let temp = JSON.parse(JSON.stringify(this.state.sort_conditions))
    temp.forEach((item) => {
      if (item.id === id) item.selected = true
      else item.selected = false
    })
    this.setState({
      sort_conditions: temp,
      active_page: 0,
      total_count: 0,
    })

    this.needSearch()
  }

  render_sort_bar_mobile() {
    // console.log('Render Sort Box')
    const { sort_box } = this.state
    var location_sel = 0,
      popular_sel = 0,
      age_sel = 0
    let temp = JSON.parse(JSON.stringify(this.state.sort_conditions))
    temp.forEach((item) => {
      if (item.id === 1 && item.selected === true) {
        location_sel = 1
        // console.log('Location Selected')
      } else if (item.id === 2 && item.selected === true) {
        popular_sel = 1
        // console.log('Popular Selected')
      } else if (item.id === 3 && item.selected === true) {
        age_sel = 1
        // console.log('Age Selected')
      }
    })

    if (sort_box) {
      return (
        <div className="box-widget sort-box">
          <div className="box-title">SORT</div>

          <div className="box-close" onClick={this.closeBox.bind(this)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>

          <div
            className="box-item text-left mt-5"
            onClick={this.clickMobileSort.bind(this, 1)}
          >
            LOCATION
            {location_sel ? <i className="ml-3 fa fa-angle-down"></i> : ''}
          </div>
          <div
            className="box-item text-left mt-5"
            onClick={this.clickMobileSort.bind(this, 2)}
          >
            RECENTLY ADDED
            {popular_sel ? <i className="ml-3 fa fa-angle-down"></i> : ''}
          </div>
          <div
            className="box-item text-left mt-5"
            onClick={this.clickMobileSort.bind(this, 3)}
          >
            AGE{age_sel ? <i className="ml-3 fa fa-angle-down"></i> : ''}
          </div>

          <div className="btn-box"></div>
        </div>
      )
    }
  }

  onClickFilterBar(e) {
    const { filter_box } = this.state
    this.setState({ filter_box: !filter_box })
  }

  onClickSortLocation(e) {
    const { sort_box } = this.state
    this.setState({ sort_box: !sort_box })
  }

  render_filter_bar_mobile() {
    let sort_title = 'Location'
    let temp = JSON.parse(JSON.stringify(this.state.sort_conditions))
    temp.forEach((item) => {
      if (item.selected === true) {
        sort_title = item.title
      }
    })

    return (
      <div className="row mt-3">
        <div className="col-5">
          <div className="filter-widget">
            <button
              type="button"
              className="btn btn-filter"
              onClick={this.onClickFilterBar.bind(this)}
            >
              <span className="filter-title">Filter</span>
              <i aria-hidden="true" className="fa fa-angle-down"></i>
            </button>
          </div>
        </div>
        <div className="col-7">
          <div className="filter-widget">
            <button
              type="button"
              className="btn btn-filter"
              onClick={this.onClickSortLocation.bind(this)}
            >
              <span className="filter-title">Sort By {sort_title}</span>
              <i aria-hidden="true" className="fa fa-angle-down"></i>
            </button>
          </div>
        </div>

        {this.render_filter_box()}
        {this.render_sort_bar_mobile()}
      </div>
    )
  }

  render_cta_mobile() {
    return (
      <div className="col-12 text-center">
        <div className="page-title-frame">
          <div className="page-title">
            <h1>Donor Search</h1>
          </div>
        </div>

        {this.render_search_keyword()}

        <div className="row">
          <div className="col-12">{this.render_search_counts()}</div>
          <div className="col-12">{this.render_location()}</div>
        </div>

        {this.render_filter_bar_mobile()}
      </div>
    )
  }

  handleKeyword(e) {
    console.log('Keyword press')

    if (e.keyCode === 13) {
      // Do something
      console.log('Keyword press Enter')
      this.setState({ active_page: 0, total_count: 0 })
      this.needSearch()
    }
  }

  handleKeyCity(e) {
    if (e.keyCode === 13) {
      // Do something
      console.log('City press Enter:', this.state.city)
      this.setState({ active_page: 0, total_count: 0 })
      this.needSearch()
    }
  }

  handleChangeCityInput(e) {
    this.setState({ city: e.target.value })
  }

  clickSearchButton(e) {
    this.setState({ active_page: 0, total_count: 0 })
    this.needSearch()
  }

  render_search_keyword() {
    return (
      <div className="content-widget mb-5" id="content_keyword">
        <div className="d-flex flex-row align-items-center justify-content-start">
          <div className="input-group">
            <div className="pl-2 input-group-prepend align-items-center">
              <i className="fa fa-search mr-3" aria-hidden="true"></i>
            </div>
            {isMobile ? (
              <input
                type="text"
                className="form-control"
                name="values[keyword]"
                id="keyword"
                placeholder="Keyword, Donor Name..."
                onKeyUp={this.handleKeyword}
              ></input>
            ) : (
              <input
                type="text"
                className="form-control"
                name="values[keyword]"
                id="keyword"
                placeholder="Keyword, Donor Name, Donor ID, City, State"
                onKeyUp={this.handleKeyword}
              ></input>
            )}
          </div>

          <div className="pl-3 col align-items-center button-col">
            <button
              type="button"
              className="btn btn-keyword"
              id="btn_search"
              onClick={this.clickSearchButton.bind(this)}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    )
  }

  handleChangeState = (option) => {
    let active_item = _.find(filter_states, (item) => item.id === option.id)
    console.log('Active Item: ', active_item)
    const key = option.value

    if (key !== '') {
      let cities = _.filter(data, (item) => item[3] === key)
      let filter_cities = _.map(cities, (item, index) => {
        return {
          id: index + 1,
          label: item[0],
          value: item[0],
        }
      })
      filter_cities.unshift({ id: 0, value: '', label: '-' })
      this.setState({
        state: active_item.key,
        filter_cities,
        selectedState: option,
        city: '',
        selectedCity: { id: 0, value: '', label: '-' },
        active_page: 0,
        total_count: 0,
      })
    } else {
      let filter_cities = [{ id: 0, value: '', label: '-' }]
      this.setState({
        state: active_item.key,
        filter_cities,
        selectedState: option,
        selectedCity: { id: 0, value: '', label: '-' },
        city: '',
        active_page: 0,
        total_count: 0,
      })
    }

    this.needSearch()
  }

  handleChangeCity = (option) => {
    const { filter_cities } = this.state

    let active_item = _.find(filter_cities, (item) => item.id === option.id)
    console.log('Active Item: ', active_item)

    this.setState({
      city: active_item.key,
      selectedCity: option,
      active_page: 0,
      total_count: 0,
    })

    this.needSearch()
  }

  resetDistanceThenSet = (id, key) => {
    const { filter_distance } = this.state
    console.log('Function call')

    let temp = JSON.parse(JSON.stringify(this.state.filter_distance))
    temp.forEach((item) => {
      if (item.id === id) item.selected = true
      else item.selected = false
    })
    this.setState({
      filter_distance: temp,
      distance: key,
      active_page: 0,
      total_count: 0,
    })

    this.needSearch()
  }

  resetSortThenSet = (id, key) => {
    const { sort_conditions } = this.state
    console.log('Function call')

    let temp = JSON.parse(JSON.stringify(this.state.sort_conditions))
    temp.forEach((item) => {
      if (item.id === id) item.selected = true
      else item.selected = false
    })
    this.setState({
      sort_conditions: temp,
      active_page: 0,
      total_count: 0,
    })

    this.needSearch()
  }

  changeSortBy() {
    const { sort_conditions } = this.state
    console.log('Sort by Directions')

    var selected_value = $('#sortby').val()
    let temp = JSON.parse(JSON.stringify(sort_conditions))
    temp.forEach((item) => {
      if (item.key === selected_value) item.selected = true
      else item.selected = false
    })

    this.setState({
      sort_conditions: temp,
      active_page: 0,
      total_count: 0,
    })

    this.needSearch()
  }

  clickSortDirection() {
    const { sort_directions } = this.state
    this.setState({
      sort_directions: !sort_directions,
      active_page: 0,
      total_count: 0,
    })
    this.needSearch()
  }

  render_sort_bar() {
    const { sort_conditions, sort_directions } = this.state

    return (
      <div className="content-widget content-sort d-flex justify-content-end align-items-center">
        <div className="widget_sort_condition">
          <span>Sort By&nbsp;&nbsp;</span>
          <select
            name="sortby"
            id="sortby"
            className=""
            onChange={this.changeSortBy.bind(this)}
          >
            {sort_conditions.map(function (obj, i) {
              return (
                <option
                  value={obj.key}
                  selected={obj.selected}
                  key={`sort-options-${obj.key}`}
                >
                  {obj.title}
                </option>
              )
            })}
          </select>
        </div>
        <div
          className="widget_sort_direction"
          onClick={this.clickSortDirection.bind(this)}
        >
          {sort_directions ? (
            <span aria-hidden="true" className="fa fa-angle-down fa-2x"></span>
          ) : (
            <span aria-hidden="true" className="fa fa-angle-up fa-2x"></span>
          )}
        </div>
      </div>
    )
  }

  changeDonorInfo(val) {
    console.log('Donor Fav Clicked:' + val)
    if (val > 1) this.needSearch()
  }

  handlePageChange(obj) {
    console.log(`active page is ${pageNumber}`)
    var pageNumber = obj.selected
    this.setState({ active_page: pageNumber })
    this.needSearch()
  }

  loadMoreData() {
    this.searchDonors(true)
  }

  render_loadmore() {
    const { total, total_count, donor_loadmore } = this.state

    //console.log('LoadMore: total:' + total + ',Display:' + total_count);
    if (total > 0 && total_count < total) {
      return (
        <div className="content-widget content-loadmore" id="content-loadmore">
          {donor_loadmore ? (
            <button className="btn btn-more" disabled>
              Load More
            </button>
          ) : (
            <button
              className="btn btn-more"
              onClick={this.loadMoreData.bind(this)}
            >
              Load More
            </button>
          )}
        </div>
      )
    }
  }

  render_pagination() {
    const { total, items_per_page, page_range, active_page } = this.state

    var page_count = total / items_per_page
    if (total > 0)
      return (
        <div
          className="content-widget content-pagination"
          id="content-pagination"
        >
          <ReactPaginate
            pageCount={page_count}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            initialPage={active_page}
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            activeClassName="active"
            disabledClassName="disabled"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            disableInitialCallback="false"
            onPageChange={this.handlePageChange.bind(this)}
          />
        </div>
      )
  }

  render_search_result() {
    const {
      donor_searching,
      donor_searching_pending,
      donor_loadmore,
      total,
      donors,
    } = this.state
    if (donor_searching > 0 || donor_searching_pending > 0) {
      this.searchDonors()
      return (
        <div className="content-widget content-donors" id="content-donors">
          <div className="loading_donor"></div>
        </div>
      )
    }
    var _this = this

    if (total === 0) {
      return (
        <div className="content-widget content-donors" id="content-donors">
          <p className="account-nothing text-center">No Donors</p>
        </div>
      )
    }

    return (
      <div className="content-widget content-donors" id="content-donors">
        <div className="row">
          {donors.map((obj, i) => {
            return (
              <div
                className="col-md-6 col-sm-6 col-xs-12"
                key={`donor-${obj.id}`}
              >
                <Donor
                  key={i}
                  name={obj.firstname}
                  age={obj.age}
                  photo={obj.default_photo}
                  bookmark={obj.bookmark}
                  city={obj.city}
                  state={obj.state}
                  rid={obj.rid}
                  bodytype={obj.bodytype}
                  hairtype={obj.hairtype}
                  did={obj.id}
                  height={obj.height}
                  weight={obj.weight}
                  eyecolor={obj.eyecolor}
                  haircolor={obj.haircolor}
                  race={obj.race}
                  show_home={obj.show_home}
                  sharedcycledisplay={obj.sharedcycledisplay}
                  freezeeggs={obj.freezeeggs}
                  donatedbefore={obj.donatedbefore}
                  onChange={_this.changeDonorInfo.bind(_this)}
                />
              </div>
            )
          })}
        </div>

        {donor_loadmore ? (
          <div className="row">
            <div className="loading_donor"></div>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }

  render_content() {
    return (
      <div className="col-xs-12 col-md-9">
        <div className="page-title-frame">
          <div className="page-title">
            <h1>Donor Search</h1>
          </div>
        </div>

        {this.render_search_keyword()}

        {this.render_sort_bar()}

        {this.render_search_result()}

        {this.render_loadmore()}
      </div>
    )
  }

  render_content_mobile() {
    return (
      <div className="col-12">
        {this.render_search_result()}

        {this.render_loadmore()}
      </div>
    )
  }

  render_main_content() {
    return (
      <div className="row">
        {isMobile ? (
          <>
            {this.render_cta_mobile()}
            {this.render_content_mobile()}
          </>
        ) : (
          <>
            {this.render_cta()}
            {this.render_content()}
          </>
        )}
      </div>
    )
  }

  render() {
    const { load_data } = this.state

    if (load_data < 0) {
      return this.render_error()
    }

    if (load_data === 0) {
      return this.render_loading()
    }

    return (
      <div>
        <Header />
        <ReactNotification ref={this.notificationDOMRef} />
        <ScrollUpButton
          ContainerClassName="ScrollUpButton__Container"
          TransitionClassName="ScrollUpButton__Toggled"
        />
        <div className="content">
          <div className="container">{this.render_main_content()}</div>
        </div>
      </div>
    )
  }
}

export default TSearch
