import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import $ from 'jquery'
import Donor from './donor'
import Header from './header'
import * as F_UTIL from './helper'
import Checkbox from 'rc-checkbox'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import ScrollUpButton from 'react-scroll-up-button'
import './App.css'

class TAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      load_data: 0,
      donor_change: 0,
      account_open: 0,
      lat: 0,
      lng: 0,
      distance: 0,
      address: '',
      filter_distance: [
        {
          id: 1,
          title: '30 miles',
          selected: false,
          key: '30',
        },
        {
          id: 2,
          title: '50 miles',
          selected: false,
          key: '50',
        },
        {
          id: 3,
          title: '100 miles',
          selected: false,
          key: '100',
        },
        {
          id: 4,
          title: '100+ miles',
          selected: true,
          key: '0',
        },
      ],
      filter_check_values: [],
    }
    this.p_info = ''
    this.reserves = []
    this.bookmarks = []
    this.p_rid = global.r_id
    this.api_message = ''
    this.addNotification = this.addNotification.bind(this)
    this.notificationDOMRef = React.createRef()
  }

  componentWillMount() {
    //var params = queryString.parse(this.props.props.location.search);
    //this.p_rid = params.id;
    F_UTIL.check_login()
    F_UTIL.check_account_type()
    document.title = global.title
    this.loadLocationInfo()
  }

  componentDidMount() {
    /*
        const script = document.createElement("script");

        script.src = "https://maps.googleapis.com/maps/api/js?key=" + global.map_key + "&libraries=places";
        script.async = true;
        document.body.appendChild(script);
        $('#autocomplete').focus();
        */
  }

  loadLocationInfo() {
    const { filter_distance } = this.state

    let settings = F_UTIL.load_location_settings()
    if (settings) {
      let temp = JSON.parse(JSON.stringify(this.state.filter_distance))
      temp.forEach((item) => {
        if (item.key === settings.distance) item.selected = true
        else item.selected = false
      })
      this.setState({
        filter_distance: temp,
        distance: settings.distance,
        lat: settings.lat,
        lng: settings.lng,
      })
    }
  }

  getAccountInfo() {
    var url = global.api_url + 'recipient/info/' + global.r_id
    console.log('Call API:', url)

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: { token: global.token },
      cache: false,
      crossDomain: true,
      success: function (response) {
        if (response.success === true) {
          //var info = JSON.parse(response);
          this.p_info = response.info
          this.reserves = response.reserve
          this.bookmarks = response.bookmark

          const { filter_check_values } = this.state
          let check_values = this.state.filter_check_values.slice()
          check_values.forEach((ref, i) => {
            check_values[i] = 0
          })

          check_values[this.p_info.mail_notify] = 1
          this.setState({
            load_data: 1,
            donor_change: 0,
            filter_check_values: check_values,
          })
          console.log('Ajax Info:', response.info)
        } else {
          this.api_message = response.message
          this.setState({ load_data: -1 })
          console.log('Ajax API failed:', this.api_message)
          F_UTIL.goto_login()
        }
      }.bind(this),
    })
  }

  onFocusAutoComplete(e) {
    e.preventDefault()
    console.log('Focused Autocomplete')
    $('#autocomplete').select()
  }

  addNotification(type, msg) {
    this.notificationDOMRef.current.addNotification({
      message: msg,
      type: type,
      insert: 'top',
      container: 'bottom-left',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
    })
  }

  updateAccountSetting(e) {
    e.preventDefault()
    const form = e.target
    const data = new FormData(form)

    console.log('Update Account Setting')
    let check_values = this.state.filter_check_values.slice()
    let notify = 0
    check_values.forEach((val, i) => {
      if (check_values[i] > 0) {
        notify = i
      }
    })

    var url = global.api_url + 'recipient/update/' + global.r_id

    $.ajax({
      url: url,
      type: 'POST',
      dataType: 'json',
      data: {
        token: global.token,
        phone: data.get('phone'),
        notify: notify,
        password: data.get('password'),
      },
      cache: false,
      crossDomain: true,
      success: function (rsp) {
        console.log('Ajax Success')
        //this.addNotification();
        if (rsp.success === true) {
          this.addNotification('success', rsp.message)
        } else if (rsp.credential === false) {
          F_UTIL.goto_login()
        } else {
          this.addNotification('danger', rsp.message)
        }
      }.bind(this),
    })
  }

  render_loading() {
    this.getAccountInfo()

    return (
      <div>
        <Header></Header>
        <div id="loading"></div>
      </div>
    )
  }

  render_error() {
    return (
      <div>
        <Header></Header>
        <div className="content">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="account-heading text-center">
                  {this.api_message}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onClickSearch(e) {
    const { lat, lng, distance } = this.state

    var addr = $('#autocomplete').val()
    if (addr === '') return

    F_UTIL.save_location_settings(addr, lat, lng, distance)

    //go to search page
    var url = '/search'
    window.location = url
  }

  resetDistanceThenSet = (id, key) => {
    const { filter_distance } = this.state
    console.log('Function call')

    let temp = JSON.parse(JSON.stringify(this.state.filter_distance))
    temp.forEach((item) => {
      if (item.id === id) item.selected = true
      else item.selected = false
    })
    this.setState({
      filter_distance: temp,
      distance: key,
    })
  }

  render_cta_search() {
    return (
      <div className="cta-widget cta-location pb-3">
        <div className="widget-title">Account Settings</div>
      </div>
    )
  }

  changeFilterCheckbox(ref, e) {
    console.log('Checkbox clicked')
    let check_values = this.state.filter_check_values.slice()
    if (check_values[ref] > 0) return
    check_values.forEach((val, i) => {
      check_values[i] = 0
    })

    check_values[ref] = 1
    this.setState({ filter_check_values: check_values })
  }

  render_cta_account() {
    const { filter_check_values } = this.state

    return (
      <div className="cta-widget">
        <form onSubmit={this.updateAccountSetting.bind(this)}>
          <input
            type="hidden"
            name="notify"
            id="notify"
            defaultValue={this.p_info.mail_notify}
          ></input>

          <div className="row">
            <div className="col-12">
              <div className="widget-subtitle pt-0">Update Password</div>
              <input
                type="password"
                className="form-control"
                name="password"
                id="password"
                placeholder="**********"
              />

              <div className="widget-subtitle">Phone number</div>

              <input
                type="text"
                className="form-control"
                name="phone"
                id="phone"
                defaultValue={this.p_info.hphone}
              ></input>
            </div>
            <div className="col-12">
              <div className="widget-subtitle">New Donor Alerts</div>

              <div className="cta-account-checkbox">
                <Checkbox
                  value="yes"
                  checked={filter_check_values[1] ? true : false}
                  name="freezeeggs_yes"
                  value="1"
                  onChange={this.changeFilterCheckbox.bind(this, 1)}
                />
                &nbsp;&nbsp;As-it-happens
              </div>
              <div className="cta-account-checkbox">
                <Checkbox
                  value="yes"
                  checked={filter_check_values[2] ? true : false}
                  name="freezeeggs_yes"
                  value="2"
                  onChange={this.changeFilterCheckbox.bind(this, 2)}
                />
                &nbsp;&nbsp;At most once a week
              </div>
              <div className="cta-account-checkbox">
                <Checkbox
                  value="yes"
                  checked={filter_check_values[0] ? true : false}
                  name="freezeeggs_yes"
                  value="0"
                  onChange={this.changeFilterCheckbox.bind(this, 0)}
                />
                &nbsp;&nbsp;No alerts
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-cta">
            Update
          </button>
        </form>
      </div>
    )
  }

  render_cta() {
    return (
      <div className="col-xs-12 col-md-3">
        {this.render_cta_search()}
        {this.render_cta_account()}
      </div>
    )
  }

  clickWidgetAccount(e) {
    const { account_open } = this.state
    this.setState({ account_open: !account_open })
    $('#widget-account').slideToggle('fast')
  }

  render_cta_mobile() {
    const { account_open, filter_check_values } = this.state

    return (
      <div className="cta-widget cta-account mt-5">
        <button
          type="submit"
          className="btn btn-search"
          id="btn_search"
          onClick={this.onClickSearch.bind(this)}
          id="btn_search"
        >
          Search Donors
        </button>

        <div
          className="widget-title text-left mt-4"
          onClick={this.clickWidgetAccount.bind(this)}
          id="account_mobile"
        >
          <span className="pl-2">ACCOUNT PREFERENCES</span>
          {account_open ? (
            <i className="fa fa-minus pull-right" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-plus pull-right" aria-hidden="true"></i>
          )}
        </div>

        <div className="widget-content" id="widget-account">
          <form onSubmit={this.updateAccountSetting.bind(this)}>
            <div className="row">
              <div className="col-6">
                <div className="cta-widget">
                  <div className="widget-subtitle">Update Password</div>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    placeholder="**********"
                  />

                  <div className="widget-subtitle">Phone number</div>

                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    id="phone"
                    defaultValue={this.p_info.hphone}
                  ></input>
                </div>
              </div>
              <div className="col-12">
                <div className="cta-widget">
                  <div className="widget-subtitle mt-2 mb-2">
                    New Donor Alerts
                  </div>
                  <div className="cta-checkbox">
                    <Checkbox
                      value="yes"
                      checked={filter_check_values[1] ? true : false}
                      name="freezeeggs_yes"
                      value="1"
                      onChange={this.changeFilterCheckbox.bind(this, 1)}
                    />
                    &nbsp;&nbsp;As-it-happens
                  </div>
                  <div className="cta-checkbox">
                    <Checkbox
                      value="yes"
                      checked={filter_check_values[2] ? true : false}
                      name="freezeeggs_yes"
                      value="2"
                      onChange={this.changeFilterCheckbox.bind(this, 2)}
                    />
                    &nbsp;&nbsp;At most once a week
                  </div>
                  <div className="cta-checkbox">
                    <Checkbox
                      value="yes"
                      checked={filter_check_values[0] ? true : false}
                      name="freezeeggs_yes"
                      value="0"
                      onChange={this.changeFilterCheckbox.bind(this, 0)}
                    />
                    &nbsp;&nbsp;No alerts
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" className="btn btn-cta">
              Update
            </button>
          </form>
        </div>
      </div>
    )
  }

  render_reserved_eggs() {
    const { donor_change } = this.state
    if (donor_change > 0) {
      return (
        <div className="" id="pane_reserved">
          <div className="account-subheading">RESERVED FROZEN EGGS</div>
          <p className="account-info-content text-left">
            Once you have found your egg donor, your match specialist will
            ensure that your donor's inventory does not become sold out. Her
            eggs will be held for you for 48-hours while we confirm your case
            and order.
          </p>
          <div className="loading_donor"></div>
        </div>
      )
    }

    var donors = this.reserves
    if (donors === undefined) donors = []

    var len = donors.length
    if (len === 0) {
      return (
        <div className="" id="pane_reserved">
          <div className="account-subheading">RESERVED FROZEN EGGS</div>
          <p className="account-nothing text-left">
            There are currently no donors reserved. To reserve frozen eggs from
            one of our exceptional donors visit a donor profile and click the
            Reserve Frozen Eggs button.
          </p>
        </div>
      )
    }

    var _this = this
    return (
      <div className="" id="pane_reserved">
        <div className="account-subheading">RESERVED FROZEN EGGS</div>
        <p className="account-info-content text-left">
          Once you have found your egg donor, your match specialist will ensure
          that your donor's inventory does not become sold out. Her eggs will be
          held for you for 48-hours while we confirm your case and order.
        </p>
        <div className="row mt-5">
          {donors.map(function (obj, i) {
            return (
              <div className="col-md-6 col-sm-6 col-xs-12">
                <Donor
                  name={obj.firstname}
                  age={obj.age}
                  photo={obj.default_photo}
                  bookmark={obj.bookmark}
                  city={obj.city}
                  state={obj.state}
                  rid={obj.rid}
                  donatedbefore={obj.donatedbefore}
                  bodytype={obj.bodytype}
                  hairtype={obj.hairtype}
                  did={obj.id}
                  height={obj.height}
                  weight={obj.weight}
                  eyecolor={obj.eyecolor}
                  haircolor={obj.haircolor}
                  race={obj.race}
                  show_home={obj.show_home}
                  sharedcycledisplay={obj.sharedcycledisplay}
                  freezeeggs={obj.freezeeggs}
                  donatedbefore={obj.donatedbefore}
                  onChange={_this.changeDonorInfo.bind(_this)}
                ></Donor>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  changeDonorInfo(val) {
    this.setState({ donor_change: val })
  }

  render_bookmarked_donors() {
    const { donor_change } = this.state

    if (donor_change > 0) {
      return (
        <div className="" id="pane_bookmarked">
          <div className="account-subheading">BOOKMARKED DONORS</div>
          <div className="loading_donor"></div>
        </div>
      )
    }

    var donors = this.bookmarks
    if (donors === undefined) donors = []
    var len = donors.length
    if (len === 0) {
      return (
        <div className="" id="pane_bookmarked">
          <div className="account-subheading">BOOKMARKED DONORS</div>
          <p className="account-nothing text-left">
            There are currently no bookmarked donors. Click the heart icon on
            the donor's profile to save them here.
          </p>
        </div>
      )
    }

    var _this = this
    return (
      <div className="" id="pane_bookmarked">
        <div className="account-subheading">BOOKMARKED DONORS</div>
        <div className="row mt-3">
          {donors.map(function (obj, i) {
            return (
              <div className="col-md-6 col-sm-6 col-xs-12">
                <Donor
                  name={obj.firstname}
                  age={obj.age}
                  photo={obj.default_photo}
                  bookmark={obj.bookmark}
                  city={obj.city}
                  state={obj.state}
                  rid={obj.rid}
                  donatedbefore={obj.donatedbefore}
                  bodytype={obj.bodytype}
                  hairtype={obj.hairtype}
                  did={obj.id}
                  height={obj.height}
                  weight={obj.weight}
                  eyecolor={obj.eyecolor}
                  haircolor={obj.haircolor}
                  race={obj.race}
                  show_home={obj.show_home}
                  sharedcycledisplay={obj.sharedcycledisplay}
                  freezeeggs={obj.freezeeggs}
                  donatedbefore={obj.donatedbefore}
                  onChange={_this.changeDonorInfo.bind(_this)}
                ></Donor>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  render_search() {
    return (
      <div className="pt-5 text-center">
        <button
          type="submit"
          className="btn btn-search"
          id="btn_search1"
          onClick={this.onClickSearch.bind(this)}
        >
          Search Donors
        </button>
      </div>
    )
  }

  render_content_mobile() {
    const { donor_change } = this.state
    var cursor

    if (donor_change === 1) {
      cursor = 'col-12 waiting'
    } else if (donor_change === 2) {
      cursor = 'col-12 waiting'
      this.getAccountInfo()
    } else {
      cursor = 'col-12 col-md-9'
    }

    return (
      <div className={cursor}>
        <div className="account-heading">Welcome to Egg Donation, Inc.!</div>
        <p className="account-info-content text-left">
          We understand that this process can be stressful, and we appreciate
          you considering Egg Donation, Inc. in your search for the donor that
          best fits your needs. We strive to make the entire process run
          smoothly so that you are free to focus on the joy that pregnancy will
          bring. Our matching specialists are available to answer any specific
          questions you might have, provide you with additional information on
          specific donors at your request and to offer suggestions as to other
          donors who might be of interest to you. Our staff is here to assist
          you every step of the way.
        </p>

        <p className="account-info-content text-left pt-3">
          For more information on our program and the process of egg donation,
          please view our
          <a
            href="https://www.eggdonor.com/recipients/egg-donation-faq"
            target="_blank"
          >
            {' '}
            Frequently Asked Questions
          </a>{' '}
          page.
        </p>

        <p className="account-info-content text-left pt-3">
          We look forward to assisting you!
        </p>

        {this.render_cta_mobile()}

        {this.render_reserved_eggs()}

        {this.render_bookmarked_donors()}

        {this.render_search()}
      </div>
    )
  }

  render_content() {
    const { donor_change } = this.state
    var cursor

    if (donor_change === 1) {
      cursor = 'col-xs-12 col-md-9 waiting'
    } else if (donor_change === 2) {
      cursor = 'col-xs-12 col-md-9 waiting'
      this.getAccountInfo()
    } else {
      cursor = 'col-xs-12 col-md-9'
    }

    return (
      <div className={cursor}>
        <div className="account-heading">Welcome to Egg Donation, Inc.!</div>
        <p className="account-info-content text-left">
          We understand that this process can be stressful, and we appreciate
          you considering Egg Donation, Inc. in your search for the donor that
          best fits your needs. We strive to make the entire process run
          smoothly so that you are free to focus on the joy that pregnancy will
          bring. Our matching specialists are available to answer any specific
          questions you might have, provide you with additional information on
          specific donors at your request and to offer suggestions as to other
          donors who might be of interest to you. Our staff is here to assist
          you every step of the way.
        </p>

        <p className="account-info-content text-left pt-3">
          For more information on our program and the process of egg donation,
          please view our
          <a
            href="https://www.eggdonor.com/recipients/egg-donation-faq"
            target="_blank"
          >
            {' '}
            Frequently Asked Questions
          </a>{' '}
          page.
        </p>

        <p className="account-info-content text-left pt-3">
          We look forward to assisting you!
        </p>

        <div className="content-widget text-left pt-5">
          <button
            type="submit"
            className="btn btn-search"
            id="btn_search"
            onClick={this.onClickSearch.bind(this)}
            id="btn_search"
          >
            Search Donors
          </button>
        </div>

        {this.render_reserved_eggs()}

        {this.render_bookmarked_donors()}

        {this.render_search()}
      </div>
    )
  }

  render_main_content() {
    if (isMobile) {
      return (
        <div className="content">
          <div className="container">
            <div className="row">
              {this.render_content_mobile()}
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="content">
        <div className="container">
          <div className="row">
            {this.render_cta()}
            {this.render_content()}

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { load_data } = this.state
    if (load_data < 0) {
      return this.render_error()
    }

    if (load_data === 0) {
      return this.render_loading()
    }

    return (
      <div>
        <Header></Header>
        <ReactNotification ref={this.notificationDOMRef} />
        <ScrollUpButton
          ContainerClassName="ScrollUpButton__Container"
          TransitionClassName="ScrollUpButton__Toggled"
        />
        <div className="page-title-frame">
          <div className="page-title text-left">
            <div className="container">
              <h1>Account Dashboard</h1>
            </div>
          </div>
        </div>
        {this.render_main_content()}
      </div>
    )
  }
}

export default TAccount
